import { MoreVertOutlined } from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch } from "../../store/redux";
import { TMoreVerticalMenuProps } from "./types/TMoreVerticalMenuProps";

const MoreVerticalMenu = (props: TMoreVerticalMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const handleOpenMoreVerticalMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreVerticalMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMoreVerticalMenu}
      >
        <MoreVertOutlined sx={{ color: "black" }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreVerticalMenu}
      >
        {props.children}
      </Menu>
    </>
  );
};

export default MoreVerticalMenu;
