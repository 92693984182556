import { MenuItem } from "@mui/material";
import { TMoreVerticalMenuButtonProps } from "./types/TMoreVerticalMenuButtonProps";

export default function MoreVerticalMenuButton(
  props: TMoreVerticalMenuButtonProps
) {
  return (
    <MenuItem onClick={props.onClick} sx={props.style}>
      {props.title}
    </MenuItem>
  );
}
