import React from "react";
import DashboardPageTitle from "../../Components/DashboardPageTitle/DashboardPageTitle";
import { IRestaurantUserEntity } from "../../../../../common/Entities/IRestaurantUserEntity";
import { useAppSelector } from "../../../../../common/store/redux";

export default function DateAndNamePageTitleContainer() {
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  return (
    <DashboardPageTitle
      title={(authenticatedUser as IRestaurantUserEntity).restaurant.name}
      date={new Date()}
    />
  );
}
