import React from "react";
import BellIcon from "../../images/header_bell_icon.png";

export default function NotificationBell() {
  return (
    <img
      src={BellIcon}
      style={{ width: "52px", height: "52px", cursor: "pointer" }}
    />
  );
}
