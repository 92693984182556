import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { TCheckIconAnimatedProps } from "./types/TCheckIconAnimatedProps";

export default function CheckIconAnimated(props: TCheckIconAnimatedProps) {
  return (
    <Box
      sx={{
        position: "absolute",
        right: 10,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 10,
        animation: "popIn 0.3s ease-out",
        "@keyframes popIn": {
          "0%": { transform: "translateY(-50%) scale(0)", opacity: 0 },
          "70%": { transform: "translateY(-50%) scale(1.2)", opacity: 1 },
          "100%": { transform: "translateY(-50%) scale(1)", opacity: 1 },
        },
      }}
    >
      <CheckOutlined
        sx={{
          color: "green",
          fontSize: 24,
          opacity: props.showAnimation ? 1 : 0,
        }}
      />
    </Box>
  );
}
