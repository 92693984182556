import { Box } from "@mui/material";
import { useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import ToolTipOverlay from "../ToolTipOverlay/ToolTipOverlay";
import { TCustomBarChartProps } from "./types/TCustomBarChartProps";

export default function CustomBarChart(props: TCustomBarChartProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [tooltipData, setTooltipData] = useState<{
    x: number;
    y: number;
    value: number;
  } | null>(null);

  const handleMouseEnter = (barProps: any, index: number) => {
    setTooltipData({
      x: barProps.x + barProps.width / 2,
      y: barProps.y,
      value: barProps.value,
    });
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setActiveIndex(null);
  };

  const handleBarShape = (barProps: any) => {
    const { fill, x, y, width, height, index } = barProps;
    const opacity = activeIndex === null || activeIndex === index ? 1 : 0.4;
    return (
      <rect
        x={x! + 10} // Adjust spacing to make bars thicker
        y={y}
        width={width! - 20} // Adjust bar thickness
        height={height}
        fill={fill}
        rx={10} // Radius for rounded corners
        style={{ opacity, transition: "opacity 0.3s ease-in-out" }}
      />
    );
  };

  return (
    <Box position="relative" width="100%" height={230}>
      {tooltipData && <ToolTipOverlay tooltipData={tooltipData} />}

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={props.data}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          barCategoryGap={20}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tickFormatter={(date) =>
              new Date(date)
                .toLocaleDateString("fr-FR", {
                  weekday: "short",
                })
                .charAt(0)
                .toUpperCase()
            }
          />
          <Bar
            dataKey="value"
            fill="#ffc107"
            radius={[20, 20, 20, 20]}
            onMouseEnter={(barProps, index) =>
              handleMouseEnter(barProps, index)
            }
            onMouseLeave={handleMouseLeave}
            shape={handleBarShape}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
