import { useState } from "react";
import { IReviewsEntity } from "../../../../../common/Entities/IReviewsEntity";
import { useAppSelector } from "../../../../../common/store/redux";

export default function useReviewsOverviewRepository() {
  const reviews = useAppSelector((state) => state.reviewSlice.reviews);
  const [lastFiveReview, setLastFiveReviews] = useState<
    IReviewsEntity[] | null
  >(null);
  const onFindLastFiveReviews = async (): Promise<boolean> => {
    if (reviews && reviews.length > 0) {
      const reviewsToSet = reviews.length > 5 ? reviews.slice(-5) : reviews;

      setLastFiveReviews(reviewsToSet);
      return true;
    }
    return false;
  };
  return { onFindLastFiveReviews, lastFiveReview };
}
