import React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Divider } from "../../../../../../common/images/divider_line.svg";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import MenuPreviewBox from "../../../Components/MenuPreviewBox/MenuPreviewBox";
import { TDisplayMenuListContainerProps } from "./types/TDisplayMenuListContainerProps";

export default function DisplayMenuListContainer(
  props: TDisplayMenuListContainerProps
) {
  const shouldDisplayDivider = (index: number, menuList: IMenuEntity[]) => {
    if (index !== menuList.length - 1) {
      return (
        <Grid
          container
          mt={2}
          mb={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Divider style={{ width: "100%" }} />
        </Grid>
      );
    }
  };
  return (
    <Grid container mt={"28px"}>
      {props.menuList.length > 0 &&
        props.menuList.map((menu, index) => {
          return (
            <div
              key={index}
              style={{ width: "100%" }}
              ref={(el) => (props.menuRefs.current[menu.id!] = el)}
            >
              <MenuPreviewBox menu={menu} />
              {shouldDisplayDivider(index, props.menuList)}
            </div>
          );
        })}
    </Grid>
  );
}
