import React from "react";
import { TDailyOrderCardProps } from "./types/TDailyOrderCardProps";
import { Box, CircularProgress, Typography } from "@mui/material";
import ColoredCard from "../../../../../../common/images/colored_card.png";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";

export default function DailyOrderCard(props: TDailyOrderCardProps) {
  const handleDisplayStatValue = (
    value: number,
    loadingState: LoadingStatesEnum
  ): string | JSX.Element => {
    switch (loadingState) {
      case LoadingStatesEnum.IDLE: {
        return `${value}`;
      }
      case LoadingStatesEnum.FAILURE: {
        return "0";
      }
      case LoadingStatesEnum.LOADING: {
        return <CircularProgress />;
      }
      case LoadingStatesEnum.SUCCESS: {
        return `${value}`;
      }
    }
  };
  return (
    <Box sx={{ marginTop: "100px" }}>
      <Typography
        className="font-poppins"
        sx={{ fontSize: "16px", fontWeight: "bold", color: "#2E2E2E" }}
      >
        Commandes journalières
      </Typography>
      <Box
        sx={{
          borderRadius: "8px",
          width: "100%",
          height: "118px",
          marginTop: "17px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative", // Add position relative here
          overflow: "hidden", // Prevent content overflow
        }}
      >
        <img
          src={ColoredCard}
          alt="Background"
          style={{
            position: "absolute", // Set absolute positioning for the image
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensure the image covers the background
            zIndex: -1, // Send the image behind the content
          }}
        />
        <Box
          sx={{
            minWidth: "178px",
            paddingLeft: "15px",
            paddingRight: "15px",
            height: "73px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "14px",
            backgroundColor: "white",
            zIndex: 1, // Ensure the content is above the image
          }}
        >
          <Typography
            sx={{ color: "#2E2E2E", fontSize: "18px", fontWeight: "400" }}
            className={"font-poppins"}
          >
            Commandes :{" "}
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              {handleDisplayStatValue(props.orders, props.loadingState)}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
