import React from "react";
import { TCustomNumberFieldProps } from "./types/TCustomNumberFieldProps";
import { StyledTextField } from "../StyledTextField/StyledTextField";

export default function CustomNumberField(props: TCustomNumberFieldProps) {

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const formattedValue = formatNumber(event.target.value);
        props.setInputValue(formattedValue);
        props.handleOnBlur && props.handleOnBlur(event);
        
    }

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const inputVal = event.target.value;
    
        // Only allow numeric input when not allowing decimals
        if (!props.allowDecimals) {
          const numericValue = inputVal.replace(/[^0-9]/g, "");
          props.setInputValue(numericValue);
          return;
        }
    
        // Allow numeric input and single dot or comma
        const numericValue = inputVal.replace(/[^0-9.,]/g, "");
        props.setInputValue(numericValue);
      };

    const formatNumber = (value: string): string => {
        // Remove any non-numeric characters except dot and comma
        const cleanedValue = value.replace(/[^0-9.,]/g, "");
    
        // Replace comma with dot if needed
        const normalizedValue = cleanedValue.replace(",", ".");
    
        // If decimals are not allowed, return only integer part
        if (!props.allowDecimals) {
          return normalizedValue.split(".")[0];
        }
    
        // Split into integer and decimal parts
        const [integerPart, decimalPart] = normalizedValue.split(".");
    
        // Trim decimal part to specified places or remove entirely if not needed
        if (decimalPart) {
          const trimmedDecimal = decimalPart.slice(0, props.decimalPlaces);
    
          // Remove trailing zeros
          const cleanedDecimal = trimmedDecimal.replace(/0+$/, "");
    
          // Combine parts
          return cleanedDecimal ? `${integerPart}.${cleanedDecimal}` : integerPart;
        }
    
        return integerPart;
      };
  return (<StyledTextField
    value={props.inputValue}
    onChange={handleChange}
    variant="outlined"
    type="text"
    placeholder={props.placeholder || ""}
    width={props.width}
    height={props.height}
    onKeyDown={props.handlePressKeyDown}
    onBlur={handleOnBlur}
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={props.showEndAdornment && {
      endAdornment: props.showEndAdornment(),
    }}
  />);
}
