import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import RHFTextField from "../../../../../common/components/RHFTextField/RHFTextField";
import { CustomForm } from "../../../../../common/containers/Form/CustomForm";
import { TAddSupplementFormAttributes } from "./types/TAddSupplementFormAttributes";
import { TAddSupplementFormProps } from "./types/TAddSupplementFormProps";
import { ReactComponent as AddIcon } from "../../../../../common/images/add_icon_small.svg";
import AddSupplementFields from "./AddSupplementFields/AddSupplementFields";
import { LoadingStatesEnum } from "../../../../../common/enums/LoadingStatesEnum";

export default function AddSupplementForm(props: TAddSupplementFormProps) {
  const [areAllFieldsFilled, setAreAllFieldsFilled] = useState<boolean>(false);
  const handleAddSupplement: SubmitHandler<TAddSupplementFormAttributes> = (
    data
  ) => {
    const formattedDate = {...data,
    supplementMaxQuantity: parseInt(`${data.supplementMaxQuantity}`),
    supplementPrice: parseFloat(`${data.supplementPrice}`),
  }
    props.onSave(formattedDate);
    console.log("cacacacac", formattedDate);
  };
  const disableButton = () => {
    if(!areAllFieldsFilled || props.loadingState === LoadingStatesEnum.LOADING){
      return true;
    }
    return false;
  }

  return (
    <CustomForm
      defaultButtonText="Ajouter"
      onSubmit={handleAddSupplement}
      name="add-supplements-form"
      onSuccess={() => {}}
      useDefaultErrorDisplay={false}
      useCustomButton={true}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <AddSupplementFields setAreAllFieldsFilled={setAreAllFieldsFilled} />
        <Button
          sx={{
            height: "56px",
            minWidth: "56px",
            borderRadius: "5px",
            backgroundColor: "#FBBC04",
            "&:hover": {
              backgroundColor: "#FBBC04",
            },
            "&:disabled": {
              backgroundColor: "rgba(251, 188, 4,0.5)",
            },
          }}
          disabled={disableButton()}
          type="submit"
        >
          <AddIcon />
        </Button>
      </Stack>
    </CustomForm>
  );
}
