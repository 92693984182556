import { Grid, Typography } from "@mui/material";
import React from "react";
import { TDashboardPageTitleProps } from "./types/TDashboardPageTitleProps";

export default function DashboardPageTitle(props: TDashboardPageTitleProps) {
  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat("fr-FR", {
      weekday: "long", // Full day name
      day: "numeric", // Day of the month
      month: "short", // Abbreviated month name
      year: "numeric", // Full year
    }).format(date);
  };

  return (
    <Grid container direction={"column"} sx={{ marginBottom: "25px" }}>
      <Typography
        className="font-barlow"
        sx={{ fontSize: "28px", color: "#383838", fontWeight: "600" }}
      >
        {props.title}
      </Typography>
      <Typography
        className="font-barlow"
        sx={{ fontSize: "16px", color: "#868686" }}
      >
        {formatDate(props.date)}
      </Typography>
    </Grid>
  );
}
