import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TCreateMenuCategoryFormProps } from "./types/TCreateMenuCategoryFormProps";
import { createMenuCategory } from "../../service/categoryServices";
import { TCreateMenuCategoryFormAttributes } from "./types/TCreateMenuCategoryFormAttributes";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { menuActions } from "../../../menu/slice/menuSlice";
import { categoryActions } from "../../slice/categorySlice";
import { Grid } from "@mui/material";
import useMenuCategoryRepository from "../repository/useMenuCategoryRepository";

export default function CreateMenuCategoryForm(
  props: TCreateMenuCategoryFormProps
) {
  const dispatch = useAppDispatch();
  const { onCreateCategory } = useMenuCategoryRepository({});

  const submitHandler: SubmitHandler<
    TCreateMenuCategoryFormAttributes
  > = async (data) => {
    await onCreateCategory({ name: data.name, menuId: props.menuId });
  };
  const successHandler = () => {
    props.onClose();
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Créer la catégorie"
      onSuccess={successHandler}
      name="create-menu-category-form"
      defaultErrorDisplayCollapse={true}
    >
      <Grid item pb={4}>
        <CustomTextField
          name="name"
          label="Nom"
          validation={{
            required: "Veuillez renseigner le nom de votre catégorie",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
    </CustomForm>
  );
}
