import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TSideBarButtonProps } from "./types/TSideBarButtonProps";
import "../../../../common/containers/styles/customFonts.css";

// Define your custom styles
const styles = {
  button: {
    backgroundColor: "white",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    height: "80px",
  },
  buttonSelected: {
    backgroundColor: "#3C3A42",
  },
  icon: {
    color: "blue",
    fontSize: "20px",
  },
  iconSelected: {
    color: "white",
  },
};

export default function SideBarButton(props: TSideBarButtonProps) {
  const location = useLocation();
  const currentPath = location.pathname; // Ensure currentPath is defined first
  // Check if the current path matches exactly or starts with the specified link
  const isExactMatch = currentPath === props.link;
  const isStartsWithMatch =
    currentPath.startsWith(props.link) &&
    currentPath !== "/dashboard" &&
    props.link !== "/dashboard";

  const isSelected = isExactMatch || isStartsWithMatch || props.selected;

  return (
    <Link
      to={props.link}
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          height: "80px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundColor: `${isSelected ? "#3C3A42" : ""}`,
          marginLeft: "12px",
          borderTopLeftRadius: "12px",
          borderBottomLeftRadius: "12px",
        }}
      >
        {isSelected ? (
          <>
            {
              <Box
                sx={{
                  width: "56px",
                  height: "56px",

                  backgroundColor: "#E3AC0C",
                  borderRadius: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {props.iconSelected}
              </Box>
            }
          </>
        ) : (
          <>{props.icon}</>
        )}
      </Box>
    </Link>
  );
}
