import React, { useState } from "react";
import AutoSaveTextField from "../../../Components/Fields/AutoSaveTextField/AutoSaveTextField";
import { TItemTitleFieldContainerProps } from "./types/TItemTitleFieldContainerProps";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useMenuItemRepository from "../../../../../Menu/modules/item/repository/useMenuItemRepository";
import { useAppSelector } from "../../../../../../common/store/redux";
import { selectSelectedMenu } from "../../../../../Menu/modules/menu/selectors/menuSelector";
import { fetchStat } from "../../../../../../utils/sharedFunctions";

export default function ItemTitleFieldContainer(
  props: TItemTitleFieldContainerProps
) {
  const [loadingState,setLoadingState] = useState<LoadingStatesEnum>(LoadingStatesEnum.IDLE);
  const selectedMenu = useAppSelector((state)=>selectSelectedMenu(state));
  const selectedCategory = useAppSelector((state)=>state.category.selectedCategory);
  const {onUpdateMenuItem} = useMenuItemRepository({selectedCategory:selectedCategory!,selectedMenu:selectedMenu!})

  const handleUpdateItemTitle = (newName:string) => {
    fetchStat(onUpdateMenuItem,setLoadingState,{menuItem:{...props.menuItem,name:newName}})
  }
  
  return (
    <AutoSaveTextField
      title="Nom"
      name="itemName"
      defaultValue={props.menuItem.name}
      onSaveValue={handleUpdateItemTitle}
      placeholder="Nom"
      loadingState={loadingState}
    />
  );
}
