import { Box, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { TMoreMenuItemModalProps } from "./types/TMoreMenuItemModalProps";
import CustomButton from "../../../../../common/components/ButtonOrange/CustomButton";

export default function MoreMenuItemModal(props: TMoreMenuItemModalProps) {
  return (
    <>
      <MuiDialog open={props.isOpen} onClose={props.handleClose} maxWidth="sm">
        <DialogTitle
          sx={{ fontSize: "25px", color: "black", textAlign: "center" }}
          className="font-barlow"
        >
          {props.menuItem.name}
        </DialogTitle>
        <Box
          sx={{
            marginLeft: "50px",
            marginRight: "50px",
            marginBottom: "15px",
            minWidth: "450px",
            minHeight: "403px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid container direction="column" gap={4}>
            <CustomButton
              content={"Supprimer"}
              onClick={props.handleDeleteMenuItem}
              color={"#FB0404"}
            />
            <CustomButton
              content={"Personaliser"}
              onClick={props.handlePersonalizeMenuItem}
            />
            <CustomButton
              content={
                props.menuItem.inactiveToday ? "Désactiver la pause" : "Mettre en pause"
              }
              onClick={props.handlePauseMenuItem}
            />
          </Grid>
        </Box>
      </MuiDialog>
    </>
  );
}
