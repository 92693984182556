import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import {
  deleteMenuItem,
  updateMenuItem,
} from "../../../../Menu/modules/item/service/itemServices";
import { TDeleteMenuItemRequest } from "../../../../Menu/modules/item/service/type/Requests/TDeleteMenuItemRequest";
import { menuActions } from "../../../../Menu/modules/menu/slice/menuSlice";

export default function useMoreMenuItemButtonRepository() {
  const dispatch = useAppDispatch();
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  const onPauseItem = async (menuItem: IMenuItemEntity) => {
    /** Need to refactor this mess this should go in a separate menuItemRepository*/
    const resultAction = await dispatch(
      updateMenuItem({
        menuItem: { ...menuItem, inactiveToday: !menuItem.inactiveToday },
      })
    );
    if (updateMenuItem.rejected.match(resultAction)) {
      return false;
    }
    selectedCategory &&
      dispatch(
        menuActions.updateMenuItem({
          menuId: selectedCategory.menuId,
          categoryId: selectedCategory.id!,
          item: menuItem,
        })
      );
    return true;
  };

  async function onDeleteItem(menuItem: IMenuItemEntity): Promise<boolean> {
    const resultAction = await dispatch(deleteMenuItem({ menuItem: menuItem }));

    if (deleteMenuItem.rejected.match(resultAction)) {
      return false;
    }
    selectedCategory &&
      dispatch(
        menuActions.deleteMenuItem({
          menuId: selectedCategory.menuId,
          categoryId: selectedCategory.id!,
          itemId: menuItem.id!,
        })
      );

    return true;
  }

  return { onPauseItem, onDeleteItem };
}
