import React from "react";
import { TItemAllergenEditorButtonProps } from "./types/TItemAllergenEditorButtonProps";
import { Box, Typography } from "@mui/material";

export default function ItemAllergenEditorButton(
  props: TItemAllergenEditorButtonProps
) {
  return (
    <Box
      sx={{
        backgroundColor:
          props.colorMode === "BLUE" ? "rgba(39, 53, 81,1)" : "white",
        width: props.width ? props.width : "84px",
        height: props.height ? props.height : "39px",
        borderRadius: "10px",
        borderColor: props.colorMode === "WHITE" ? "#E8EAED" : "",
        border: props.colorMode === "WHITE" ? "2px solid" : "",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "relative",
        cursor: props.onClick ? "pointer" : "default",
      }}
      onClick={() => {
        props.disabled === false && props.onClick && props.onClick();
      }}
    >
      {props.topCornerIcon && (
        <Box
          sx={{ position: "absolute", top: -4, right: -4, cursor: "pointer" }}
          onClick={(event: any) => {
            event.stopPropagation();
            props.onClickTopCorner && props.onClickTopCorner();
          }}
        >
          {props.topCornerIcon}
        </Box>
      )}

      {React.isValidElement(props.content) ? (
        props.content
      ) : (
        <Typography
          className="font-sen"
          textAlign={"center"}
          sx={{
            fontSize: "12px",
            color: props.colorMode === "BLUE" ? "white" : "black",
          }}
        >
          {props.content}
        </Typography>
      )}
    </Box>
  );
}
