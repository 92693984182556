import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../../../../../common/components/CustomTextField/CustomTextField";
import useAutoSaveField from "../../../Hooks/useAutoSaveField/useAutoSaveField";
import AutoSaveConfirmationBox from "../AutoSaveConfirmationBox/AutoSaveConfirmationBox";
import MultilineLoadingManagement from "./MultilineLoadingManagement/MultilineLoadingManagement";
import { TAutoSaveTextFieldProps } from "./types/TAutoSaveTextFieldProps";

export default function AutoSaveTextField(props: TAutoSaveTextFieldProps) {
  const [inputValue, setInputValue] = useState(props.defaultValue || "");

  const {
    handleKeepModifying,
    handleResetField,
    showEndAdornment,
    handlePressKeyDown,
    handleSaveValue,
    showSuccessAnimation,
    showConfirmationBox,
    setShowConfirmationBox,
  } = useAutoSaveField({
    defaultValue: props.defaultValue,
    inputValue: inputValue,
    setInputValue: setInputValue,
    onSaveValue: props.onSaveValue,
    loadingState: props.loadingState,
  });

  const shouldSaveValue = (value: string): boolean => {
    if (value === props.defaultValue || value === "") {
      return false;
    }
    return true;
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const value = event.target.value;
    if (shouldSaveValue(value)) {
      setShowConfirmationBox(true);
    }
  };

  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography
          className="font-sen"
          sx={{ fontSize: "13px", color: "black", textTransform: "uppercase" }}
          mb={1}
        >
          {props.title}
        </Typography>
      </Grid>

      <Grid item sx={{ position: "relative" }}>
        {props.multiline && (
          <MultilineLoadingManagement
            showSuccessAnimation={showSuccessAnimation}
            loadingState={props.loadingState}
          />
        )}
        {showConfirmationBox && (
          <AutoSaveConfirmationBox
            onUpdate={handleKeepModifying}
            onReset={handleResetField}
            onConfirm={handleSaveValue}
          />
        )}

        <CustomTextField
          handleOnBlur={handleOnBlur}
          inputValue={inputValue}
          setInputValue={setInputValue}
          showEndAdornment={showEndAdornment}
          width={props.width}
          height={props.height}
          placeholder={props.placeholder}
          handlePressKeyDown={handlePressKeyDown}
          multiline={props.multiline}
          rows={props.rows}
        />
      </Grid>
    </Grid>
  );
}
