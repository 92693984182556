import { updateMenuItemImage } from "../../../modules/Menu/modules/image/services/menuItemImageServices";
import { menuActions } from "../../../modules/Menu/modules/menu/slice/menuSlice";
import { IMenuItemImageEntity } from "../../Entities/IMenuItemImageEntity";
import { appActions } from "../../store/appSlice";
import { useAppDispatch } from "../../store/redux";

export default function useMenuItemImageRepository() {
  const dispatch = useAppDispatch();

  async function onUpdateMenuItemImage(
    previousMenuItemImage: IMenuItemImageEntity,
    fileToUpload: File,
    newImageUrl: string
  ): Promise<boolean> {
    const resultAction = await dispatch(
      updateMenuItemImage({
        menuItemImage: previousMenuItemImage,
        file: fileToUpload,
      })
    );

    if (updateMenuItemImage.rejected.match(resultAction)) {
      return false;
    }
    dispatch(
      menuActions.updateMenuItemImage({
        menuItemId: previousMenuItemImage.menuItemId,
        newImageUrl: newImageUrl,
      })
    );

    return true;
  }

  async function onDeleteMenuItemImage(
    previousMenuItemImage: IMenuItemImageEntity
  ): Promise<boolean> {
    const resultAction = await dispatch(
      updateMenuItemImage({ menuItemImage: previousMenuItemImage, file: null })
    );

    if (updateMenuItemImage.rejected.match(resultAction)) {
      return false;
    }
    dispatch(
      menuActions.updateMenuItemImage({
        menuItemId: previousMenuItemImage.menuItemId,
        newImageUrl: null,
      })
    );
    return true;
  }
  const onDisplayErrorMessage = (message: string) => {
    dispatch(appActions.setErrorMessage(message));
  };
  return {
    onUpdateMenuItemImage,
    onDeleteMenuItemImage,
    onDisplayErrorMessage,
  };
}
