import { Box, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { TEditableTitleProps } from "./types/TEditableTitleProps";

export default function EditableTitle(props: TEditableTitleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(props.title);

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTitle(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isEditing) {
      if (currentTitle !== "") {
        if (currentTitle !== props.title) {
          props.onUpdateTitle(currentTitle);
        }
        setIsEditing(false);
      }
    }
  };

  return (
    <Grid container direction={"row"} alignItems="center" spacing={1}>
      <Grid item>
        {isEditing ? (
          <TextField
            value={currentTitle}
            onChange={handleTitleChange}
            onKeyDown={handleKeyDown}
            autoFocus
            variant="standard" // Minimal style
            size="small"
            InputProps={{
              disableUnderline: true, // Remove the underline
              style: {
                fontSize: "inherit", // Match the text size
                fontWeight: "inherit", // Match the font weight
                color: "inherit", // Match the text color
                padding: 0, // Remove padding
              },
            }}
            sx={{
              width: "auto", // Match the text width dynamically
              background: "transparent", // Remove background
            }}
            onBlur={() => setIsEditing(false)} // Exit editing when focus is lost
          />
        ) : (
          <Typography
            onClick={handleTitleClick}
            sx={{
              ...props.style,
              cursor: "pointer",
            }}
          >
            {currentTitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
