import { useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import AutoSaveTextField from "../../../Components/Fields/AutoSaveTextField/AutoSaveTextField";
import { TItemDescriptionFieldContainerProps } from "./types/TItemDescriptionFieldContainerProps";
import { useAppSelector } from "../../../../../../common/store/redux";
import useMenuItemRepository from "../../../../../Menu/modules/item/repository/useMenuItemRepository";
import { selectSelectedMenu } from "../../../../../Menu/modules/menu/selectors/menuSelector";
import { fetchStat } from "../../../../../../utils/sharedFunctions";

export default function ItemDescriptionFieldContainer(
  props: TItemDescriptionFieldContainerProps
) {
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );
  const selectedMenu = useAppSelector((state)=>selectSelectedMenu(state));
  const selectedCategory = useAppSelector((state)=>state.category.selectedCategory);
  const {onUpdateMenuItem} = useMenuItemRepository({selectedCategory:selectedCategory!,selectedMenu:selectedMenu!})

  const handleUpdateItemDescription = async (newDescription:string) => {
    fetchStat(onUpdateMenuItem,setLoadingState,{menuItem:{...props.menuItem,description:newDescription}})
  }
  return (
    <AutoSaveTextField
      title="Description"
      name="itemDescription"
      defaultValue={props.menuItem.description}
      onSaveValue={handleUpdateItemDescription}
      placeholder="Description"
      multiline={true}
      loadingState={loadingState}
    />
  );
}
