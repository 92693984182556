import React from 'react'
import { TOnFindAllPaymentsByUserRefAndDateRequest } from '../../../modules/Payment/services/types/Requests/TOnFindAllPaymentsByUserRefAndDateRequest';
import { onFindAllPaymentsByUserRefAndDate } from '../../../modules/Payment/services/paymentServices';
import { useAppDispatch } from '../../store/redux';
import { paymentActions } from '../../../modules/Payment/slices/paymentSlice';

export default function usePaymentRepository() {
    const dispatch = useAppDispatch();
  const onFindByUserRefAndDate = async (requestData: TOnFindAllPaymentsByUserRefAndDateRequest): Promise<boolean> => {
      const resultAction = await dispatch(onFindAllPaymentsByUserRefAndDate(requestData));
  
      if (onFindAllPaymentsByUserRefAndDate.rejected.match(resultAction)) {
        return false;
      }
      dispatch(paymentActions.setPayments(resultAction.payload.payments));
      return true;
    };
    return { onFindByUserRefAndDate };
}
