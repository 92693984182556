import React from "react";
import CustomButtonOrange from "../../../Components/CustomButtonOrange/CustomButtonOrange";
import CreateMenuItemModal from "../../../../../Menu/modules/item/container/Modals/CreateMenuItemModal";
import { TCreateMenuItemContainerProps } from "./types/TCreateMenuItemContainerProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { itemActions } from "../../../../../Menu/modules/item/slice/itemSlice";

export default function CreateMenuItemContainer(
  props: TCreateMenuItemContainerProps
) {
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  const dispatch = useAppDispatch();
  const handleCreateMenuItem = () => {
    dispatch(itemActions.setIsCreateMenuItemModalOpen(true));
  };
  return selectedCategory ? (
    <>
      <CreateMenuItemModal
        selectedCategory={selectedCategory}
        selectedMenu={props.menu}
      />
      <CustomButtonOrange
        title="Ajouter articles"
        onClick={handleCreateMenuItem}
      />
    </>
  ) : (
    <></>
  );
}
