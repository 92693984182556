import { Box } from "@mui/material";
import ImageUploadBox from "../ImageUploadBox/ImageUploadBox";
import useImageUpload from "../../Hooks/useImageUpload/useImageUpload";
import { useEffect } from "react";
import { FileStateEnum } from "../../../../../common/enums/FileStateEnum";
import useMenuItemImageRepository from "../../../../../common/new/repositories/useMenuItemImageRepository";
import { TImageSectionProps } from "./types/TImageSectionProps";

export default function ImageSection(props: TImageSectionProps) {
  /* This is supposed to be a container */
  const {
    file,
    image,
    handleUploadFile,
    handleDeleteImage,
    fileState,
    fileUploadError,
  } = useImageUpload({
    defaultImage: props.previousItemImage.image.url,
  });

  const {
    onUpdateMenuItemImage,
    onDeleteMenuItemImage,
    onDisplayErrorMessage,
  } = useMenuItemImageRepository();

  useEffect(() => {
    switch (fileState) {
      case FileStateEnum.TO_DELETE: {
        onDeleteMenuItemImage(props.previousItemImage);
        break;
      }
      case FileStateEnum.TO_UPLOAD: {
        file &&
          image &&
          onUpdateMenuItemImage(props.previousItemImage, file, image);
        break;
      }
      case FileStateEnum.ERROR: {
        fileUploadError && onDisplayErrorMessage(fileUploadError.message);
        break;
      }
      default: {
        break;
      }
    }
  }, [fileState, file, image]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "101px",
        marginBottom: "10px",
      }}
    >
      <ImageUploadBox
        image={image}
        handleUploadFile={handleUploadFile}
        handleDeleteImage={handleDeleteImage}
      />
    </Box>
  );
}
