import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import { TSaveButtonProps } from "./types/TSaveButtonProps";
import CustomButton from "../../../../../../common/components/ButtonOrange/CustomButton";

export default function SaveButton(props: TSaveButtonProps) {
  return (
    <CustomButton
      content={
        props.loadingState === LoadingStatesEnum.LOADING ? (
          <Grid
            container
            sx={{
              marginTop: "14px",
              marginBottom: "14px",
              marginLeft: "25px",
              marginRight: "25px",
              textAlign: "center",
              height: "25px",
              alignContent: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              marginTop: "14px",
              marginBottom: "14px",
              marginLeft: "25px",
              marginRight: "25px",
              textAlign: "center",
              height: "25px",
              alignContent: "center",
            }}
          >
            <Typography
              className="font-barlow"
              sx={{ fontSize: "14px", color: "white" }}
            >
              Sauvegarder
            </Typography>
          </Grid>
        )
      }
      onClick={() => {
        props.loadingState !== LoadingStatesEnum.LOADING && props.handleClick();
      }}
    />
  );
}
