import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../common/store/redux";
import { TItemSupplementEditorContainerProps } from "./types/TItemSupplementEditorContainerProps";
import SupplementTable from "../../../Components/SupplementTable/SupplementTable";
import AddSupplementForm from "../../../Forms/AddSupplementForm/AddSupplementForm";
import useMenuItemSupplementRepository from "../../../../../../common/new/repositories/useMenuItemSupplementRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import { TAddSupplementFormAttributes } from "../../../Forms/AddSupplementForm/types/TAddSupplementFormAttributes";
import { useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";

export default function ItemSupplementEditorContainer(
  props: TItemSupplementEditorContainerProps
) {
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );
  const { onCreateSupplement, onDeleteSupplement } =
    useMenuItemSupplementRepository();
  const handleCreateSupplement = (data: TAddSupplementFormAttributes) => {
    const requestData = {
      name: data.supplementName,
      maxQuantity: data.supplementMaxQuantity,
      itemId: props.menuItem.id!,
      price: data.supplementPrice,
    };
    //Actually delete it
    //When delete loading we cannot add in the fields or delete another
    //There is a loading circle on the whole table
    //Same thing when we create
    fetchStat(onCreateSupplement, setLoadingState, requestData);
  };
  const handleDeleteSupplement = (supplementId: number) => {
    //Actually delete it
    //When delete loading we cannot add in the fields or delete another
    //There is a loading circle on the whole table
    //Same thing when we create
    fetchStat(onDeleteSupplement, setLoadingState, {
      supplementId: supplementId,
    });
  };
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography
          className="font-sen"
          sx={{ fontSize: "13px", color: "black", textTransform: "uppercase" }}
          mb={2}
        >
          Garnitures
        </Typography>
      </Grid>
      <AddSupplementForm
        onSave={handleCreateSupplement}
        loadingState={loadingState}
      />
      <Grid container mb={2} mt={2}>
        {props.menuItem.supplements.length > 0 && (
          <SupplementTable
            supplementList={props.menuItem.supplements}
            onDelete={handleDeleteSupplement}
            loadingState={loadingState}
          />
        )}
      </Grid>
    </Grid>
  );
}
