import { useLocation } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

export default function HeaderLayout() {
  const location = useLocation();

  // Vérifie si l'URL commence par "/dashboard"
  const isDashboard = location.pathname.startsWith("/dashboard");

  const showContent = () => {
    return isDashboard ? <></> : <></>;
  };

  return showContent();
}
