import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import "../../../../common/containers/styles/customFonts.css";

import SignoutButton from "../../../Auth/containers/SignoutButton/SignoutButton";
import SideBarButtonList from "../SideBarButtons/SideBarButtonList";
import LogoCard from "../../../../common/components/LogoCard/LogoCard";

export default function DashboardSideBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const sidebarWidth = isMobile ? "180px" : isTablet ? "180px" : "231px";
  const fontSize = isMobile ? "14px" : "16px";
  const padding = isMobile ? "15px" : "25px";

  return (
    <Box
      sx={{
        width: "104px",
        height: "100%",
        backgroundColor: "#1F1D2B",
        borderBottomRightRadius: "16px",
      }}
    >
      <LogoCard />
      <SideBarButtonList />
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "104px",
          marginBottom: "25px",
          bottom: 0,
          position: "absolute",
        }}
      >
        <SignoutButton />
      </Grid>
    </Box>
  );
}
