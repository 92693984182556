import { Grid } from "@mui/material";

import MoreVerticalMenuContainer from "../../Containers/MenuDashboardPage/MoreVerticalMenuContainer/MoreVerticalMenuContainer";
import MenuItemCard from "../MenuItemCard/MenuItemCard";
import MenuTitleWithTimeframe from "../MenuTitleWithTimeframe/MenuTitleWithTimeframe";
import { TMenuPreviewBoxProps } from "./types/TMenuPreviewBoxProps";
import { useAppDispatch } from "../../../../../common/store/redux";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { menuActions } from "../../../../Menu/modules/menu/slice/menuSlice";
import { useNavigate } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex";

export default function MenuPreviewBox(props: TMenuPreviewBoxProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoToMenu = (menu: IMenuEntity) => {
    dispatch(menuActions.setSelectedMenu(menu));
    navigate(`/dashboard/menus/${menu.id!}`);
  };
  return (
    <>
      <Grid
        container
        sx={{
          borderRadius: "17px",
          padding: "15px",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.025)",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          handleGoToMenu(props.menu);
        }}
      >
        <Grid container direction={"row"} alignItems={"center"} mb={2}>
          <div
            style={{ zIndex: 9999 }}
            onClick={(event) => {
              event.stopPropagation(); // Stop event from bubbling up
            }}
          >
            <MoreVerticalMenuContainer menu={props.menu} />
          </div>

          <MenuTitleWithTimeframe
            name={props.menu.name}
            activeFrom={props.menu.activeFrom}
            activeTo={props.menu.activeTo}
          />
        </Grid>
        <Grid
          container
          direction={"row"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: 15.5,
            alignItems: "center",
          }}
        >
          {props.menu?.categories[0]?.itemList.length > 0 &&
            props.menu.categories[0].itemList.map((item, index) => {
              if (index < 4) {
                return (
                  <div key={index}>
                    <MenuItemCard menuItem={item} clickable={false} />
                  </div>
                );
              }
            })}
        </Grid>
      </Grid>
    </>
  );
}
