import BlankImageUploadBox from "../BlankImageUploadBox/BlankImageUploadBox";
import ImageEditorBox from "../ImageEditorBox/ImageEditorBox";
import { TImageUploadBoxProps } from "./types/TImageUploadBoxProps";

export default function ImageUploadBox(props: TImageUploadBoxProps) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!props.image ? (
        <BlankImageUploadBox handleUploadFile={props.handleUploadFile} />
      ) : (
        <ImageEditorBox
          image={props.image}
          handleDeleteImage={props.handleDeleteImage}
          handleUploadFile={props.handleUploadFile}
        />
      )}
    </div>
  );
}
