import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddButtonOrange from "../../../Components/AddButtonOrange/AddButtonOrange";
import DeleteMenuItemConfirmationModal from "../../../Modals/DeleteMenuItemConfirmationModal/DeleteMenuItemConfirmationModal";
import MoreMenuItemModal from "../../../Modals/MoreMenuItemModal/MoreMenuItemModal";
import useMoreMenuItemButtonRepository from "../../../Repositories/MenuEditorPage/useMoreMenuItemButtonRepository";
import { TMoreButtonMenuItemContainerProps } from "./types/TMoreButtonMenuItemContainerProps";
import { useAppSelector } from "../../../../../../common/store/redux";

export default function MoreButtonMenuItemContainer(
  props: TMoreButtonMenuItemContainerProps
) {
  const selectedMenu = useAppSelector((state) => state.menu.selectedMenu);
  const [isMoreModalOpen, setIsMoreModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const { onPauseItem, onDeleteItem } = useMoreMenuItemButtonRepository();
  const navigate = useNavigate();
  const handleCloseMoreModal = () => {
    setIsMoreModalOpen(false);
  };
  const handleOpenDeleteConfirmationModal = () => {
    handleCloseMoreModal();
    setIsDeleteConfirmationModalOpen(true);
  };
  const handleCloseDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  const handleDeleteMenuItem = async () => {
    await onDeleteItem(props.menuItem);
    handleCloseDeleteConfirmationModal();
  };
  const handlePauseMenuItem = async () => {
    console.log("pause");
    await onPauseItem(props.menuItem);
    handleCloseMoreModal();
  };
  const handlePersonalizeMenuItem = () => {
    navigate(
      `/dashboard/menus/${selectedMenu?.id!}/category/${props.menuItem.categoryId}/item/${props.menuItem.id!}`
    );
    handleCloseMoreModal();
  };

  return (
    <>
      <DeleteMenuItemConfirmationModal
        handleDeleteMenuItem={handleDeleteMenuItem}
        handleClose={handleCloseDeleteConfirmationModal}
        isOpen={isDeleteConfirmationModalOpen}
        menuItem={props.menuItem}
      />
      <MoreMenuItemModal
        menuItem={props.menuItem}
        handleClose={handleCloseMoreModal}
        isOpen={isMoreModalOpen}
        handleDeleteMenuItem={handleOpenDeleteConfirmationModal}
        handlePauseMenuItem={handlePauseMenuItem}
        handlePersonalizeMenuItem={handlePersonalizeMenuItem}
      />
      <AddButtonOrange
        onClick={() => {
          setIsMoreModalOpen(true);
        }}
      />
    </>
  );
}
