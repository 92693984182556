import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { clearCartState } from "../../../utils/cart/cartLocalStorage";
import { cartActions } from "../slices/cartSlice";
import { TGetCartRequest } from "./types/Requests/TGetCartRequest";
import { TInitiateCartRequest } from "./types/Requests/TInitiateCartRequest";
import { TGetCartResponse } from "./types/Responses/TGetCartResponse";
import { TInitiateCartResponse } from "./types/Responses/TInitiateCartResponse";

import { TFindPaymentCartRequest } from "./types/Requests/TFindPaymentCartRequest";
import { TOnFindAllCartsByUserRefAndDateRequest } from "./types/Requests/TOnFindAllCartsByUserRefAndDateRequest";
import { TFindAvailableCartResponse } from "./types/Responses/TFindAvailableCartResponse";
import { TFindPaymentCartResponse } from "./types/Responses/TFindPaymentCartResponse";
import { TOnFindAllCartsByUserRefAndDateResponse } from "./types/Responses/TOnFindAllCartsByUserRefAndDateResponse";
import { TOnGetCartOrdersResponse } from "./types/Responses/TOnGetCartOrdersResponse";

export const initiateCart = createAsyncThunk(
  "cart/initiate-cart",
  async (request: TInitiateCartRequest, { dispatch }) => {
    const response = await apiRequest<TInitiateCartResponse>(
      dispatch,
      "/api/cart/initiate-cart",
      MethodEnum.POST,
      request,
      false,
      request.cart.userRef
    );
    if (response.success) {
      clearCartState();
    }
  }
);
export const getCarts = createAsyncThunk(
  "cart/find",
  async (request: TGetCartRequest, { dispatch }) => {
    const response = await apiRequest<TGetCartResponse>(
      dispatch,
      `/api/cart/find/${request.userRef}/${request.table}`,
      MethodEnum.GET,
      null,
      false,
      request.userRef
    );
    if (response.success) {
      if (response.data.status.status === "READY") {
        dispatch(cartActions.setPreviousCarts(response.data!.cart!));
      }
    }
  }
);

export const findOrdersNbr = createAsyncThunk(
  "cart/find-ordersNbr",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGetCartOrdersResponse>(
      dispatch,
      `/api/cart/find-ordersNbr`,
      MethodEnum.GET,
      null,
      false
    );
    if (response.success) {
      dispatch(cartActions.setOrdersNbr(response.data.ordersNbr));
    }
  }
);

export const findAllCartsByUserRefAndDate = createAsyncThunk(
  "cart/find-allByUserRefAndDate",
  async (
    request: TOnFindAllCartsByUserRefAndDateRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TOnFindAllCartsByUserRefAndDateResponse>(
      dispatch,
      `/api/cart/find-allByUserRefAndDate/${request.startDate}/${request.endDate}`,
      MethodEnum.GET,
      null,
      false
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const findAvailableCart = createAsyncThunk(
  "cart/find-available",
  async (request: TGetCartRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAvailableCartResponse>(
      dispatch,
      `/api/cart/find-available/${request.userRef}/${request.table}`,
      MethodEnum.GET,
      null,
      false,
      request.userRef
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const findPaymentCart = createAsyncThunk(
  "cart/find-payment-cart",
  async (request: TFindPaymentCartRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindPaymentCartResponse>(
      dispatch,
      `/api/cart/find-payment-cart/${request.userRef}/${request.table}`,
      MethodEnum.GET,
      null,
      false,
      request.userRef
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
