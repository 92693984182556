import React from "react";
import { TMenuItemCardProps } from "./types/TMenuItemCardProps";
import { Box, Typography, Grid } from "@mui/material";
import ImageSection from "../MenuItemCardImageSection/ImageSection";
import TextContentSection from "../MenuItemCardTextContentSection/TextContentSection";
import FooterSection from "../MenuItemCardFooterSection/FooterSection";

export default function MenuItemCard(props: TMenuItemCardProps) {
  return (
    <>
      {props.clickable === false && (
        <Box
          sx={{
            height: "285px", // Keep the card height consistent
            width: "239px",
            backgroundColor: "transparent",
            position: "absolute",
            zIndex: "1",
          }}
        ></Box>
      )}

      <Box
        sx={{
          border: "1px solid",
          borderColor: "#D9D9D9",
          height: "285px", // Keep the card height consistent
          width: "239px",
          borderRadius: "20px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Image/Top Section */}
        <ImageSection previousItemImage={props.menuItem.menuItemImage} />

        {/* Content Section */}
        <Box
          sx={{
            flexGrow: 1, // Ensures this section grows/shrinks to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Space between text and actions
          }}
        >
          {/* Text Content */}
          <TextContentSection
            name={props.menuItem.name}
            description={props.menuItem.description}
          />

          {/* Footer Section */}
          <FooterSection menuItem={props.menuItem} />
        </Box>
      </Box>
    </>
  );
}
