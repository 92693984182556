import React from 'react'
import { TCustomTextFieldProps } from './types/TCustomTextFieldProps'
import { StyledTextField } from '../StyledTextField/StyledTextField';

export default function CustomTextField(props:TCustomTextFieldProps) {

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        props.setInputValue(event.target.value);
      };
  return (
    <>
            <StyledTextField
          value={props.inputValue}
          onChange={props.handleChange ? props.handleChange : handleChange}
          variant="outlined"
          type="text"
          placeholder={props.placeholder}
          width={props.width}
          height={props.height}
          onKeyDown={props.handlePressKeyDown &&props.handlePressKeyDown }
          onBlur={props.handleOnBlur && props.handleOnBlur}
          multiline={props.multiline}
          rows={props.multiline ? props.rows || 4 : undefined}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={props.showEndAdornment && {
            endAdornment: props.showEndAdornment(),
          }}
        /></>
  )
}
