import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddAllergenButton from "../AddAllergenButton/AddAllergenButton";
import AllergenDisplayButton from "../AllergenDisplayButton/AllergenDisplayButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { TItemAllergenEditorContainerProps } from "./types/TItemAllergenEditorContainerProps";
import { menuItemAllergenActions } from "../../../../../Menu/modules/allergens/slices/menuItemAllergen";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import useMenuItemAllergenRepository from "../../../../../../common/new/repositories/useMenuItemAllergenRepository";

export default function ItemAllergenEditorContainer(
  props: TItemAllergenEditorContainerProps
) {
  const allAllergensList = useAppSelector(
    (state) => state.menuItemAllergen.allAllergensList
  );
  const currentItemAllergenList = useAppSelector(
    (state) => state.menuItemAllergen.currentItemAllergenList
  );
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );

  const {
    onGetAllMenuAllergen,
    onUpdateMenuItemAllergenFromList,
    onDeleteAllergen,
  } = useMenuItemAllergenRepository();

  const handleUpdateMenuItemAllergen = () => {
    const options = {
      oldList: props.menuItem.menuItemAllergen,
      newList: currentItemAllergenList,
    };
    console.log("currentItemAllergenList", currentItemAllergenList);
    fetchStat(onUpdateMenuItemAllergenFromList, setLoadingState, options);
  };

  const handleDeleteMenuItemAllergen = (menuItemAllergenId: number) => {
    fetchStat(onDeleteAllergen, setLoadingState, {
      menuItemAllergenId: menuItemAllergenId,
    });
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    props.menuItem.menuItemAllergen &&
      dispatch(
        menuItemAllergenActions.setCurrentItemAllergenList(
          props.menuItem.menuItemAllergen
        )
      );
  }, [JSON.stringify(props.menuItem)]);

  useEffect(() => {
    !allAllergensList && fetchStat(onGetAllMenuAllergen, setLoadingState);
  }, [JSON.stringify(allAllergensList)]);
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography
          className="font-sen"
          sx={{ fontSize: "13px", color: "black", textTransform: "uppercase" }}
          mb={2}
        >
          Allergènes
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: "flex-start", // Align items like flex-start
          gap: 1,
        }}
      >
        {props.menuItem.menuItemAllergen.map((itemAllergen) => (
          <AllergenDisplayButton
          key={itemAllergen.id!}
            menuItemAllergen={itemAllergen}
            enableBig={false}
            handleDeleteMenuItemAllergen={handleDeleteMenuItemAllergen}
          />
        ))}
        {allAllergensList ? (
          <AddAllergenButton
            menuItem={props.menuItem}
            handleUpdateMenuItemAllergen={handleUpdateMenuItemAllergen}
            loadingState={loadingState}
            allAllergensList={allAllergensList}
          />
        ) : (
          <>{/** Loading state here */}</>
        )}
      </Grid>
    </Grid>
  );
}
