import { useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import AutoSaveNumberField from "../../../Components/Fields/AutoSaveTextField copy/AutoSaveNumberField";
import { TItemPriceFieldContainerProps } from "./types/TItemPriceFieldContainerProps";
import useMenuItemRepository from "../../../../../Menu/modules/item/repository/useMenuItemRepository";
import { useAppSelector } from "../../../../../../common/store/redux";
import { selectSelectedMenu } from "../../../../../Menu/modules/menu/selectors/menuSelector";
import { fetchStat } from "../../../../../../utils/sharedFunctions";

export default function ItemPriceFieldContainer(
  props: TItemPriceFieldContainerProps
) {
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );
  const selectedMenu = useAppSelector((state) => selectSelectedMenu(state));
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  const { onUpdateMenuItem } = useMenuItemRepository({
    selectedCategory: selectedCategory!,
    selectedMenu: selectedMenu!,
  });

  const handleUpdateItemPrice = (newPrice: string) => {
    fetchStat(onUpdateMenuItem, setLoadingState, {
      menuItem: { ...props.menuItem, price: parseFloat(newPrice) },
    });
  };
  return (
    <AutoSaveNumberField
      title="Prix"
      name="itemPrice"
      defaultValue={`${props.menuItem.price}`}
      onSaveValue={handleUpdateItemPrice}
      placeholder="Nom"
      allowDecimals={true}
      decimalPlaces={2}
      loadingState={loadingState}
    />
  );
}
