import React from "react";
import { TCustomButtonOrangeProps } from "./types/TCustomButtonOrangeProps";
import ButtonOrange from "../../../../../common/components/ButtonOrange/CustomButton";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as AddIcon } from "../../../../../common/images/add_icon_small.svg";

export default function CustomButtonOrange(props: TCustomButtonOrangeProps) {
  return (
    <ButtonOrange
      onClick={props.onClick}
      content={
        <Grid
          container
          direction={"row"}
          sx={{
            marginLeft: "25px",
            marginRight: "25px",
            marginTop: "13px",
            marginBottom: "13px",
            gap: 1,
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddIcon />
          </Grid>
          <Grid item>
            <Typography
              className="font-barlow"
              sx={{ color: "white", fontSize: "14px" }}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}
