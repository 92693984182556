import { stripeAccountActions } from "../../../modules/Account/modules/StripeAccount/slices/stripeAccountSlice";
import {
  createConnectAccount,
  getStripeAccount,
  getUpdateStripeAccoutLink,
} from "../../../modules/Account/services/stripeAccountService";
import { TOnCreateConnectAccountRequest } from "../../../modules/Account/services/types/Requests/TOnCreateConnectAccountRequest";
import { appActions } from "../../store/appSlice";
import { useAppDispatch } from "../../store/redux";

export default function useStripeAccountRepository() {
  const dispatch = useAppDispatch();

  const onFindStripeAccount = async (): Promise<boolean> => {
    const resultAction = await dispatch(getStripeAccount());

    if (getStripeAccount.rejected.match(resultAction)) {
      return false;
    }

    dispatch(
      stripeAccountActions.setStripeAccount(resultAction.payload.account)
    );
    dispatch(stripeAccountActions.setIsStripeAccount(true));
    return true;
  };
  
  const onCreateStripeAccount = async (
    requestData: TOnCreateConnectAccountRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(createConnectAccount(requestData));

    if (createConnectAccount.rejected.match(resultAction)) {
      dispatch(stripeAccountActions.openErrorModal(true));
      return false;
    }

    dispatch(
      appActions.setSuccessMessage(
        "Vous allez être redirigé vers la page d'inscription au compte Stripe connecté..."
      )
    );
    window.location.href = resultAction.payload.accountLink.url;
    dispatch(getStripeAccount());
    dispatch(stripeAccountActions.openSuccessModal(true));
    return true;
  };
  const onGetUpdateAccountLink = async (

  ): Promise<boolean> => {
    const resultAction = await dispatch(getUpdateStripeAccoutLink());

    if (getUpdateStripeAccoutLink.rejected.match(resultAction)) {
      dispatch(stripeAccountActions.openErrorModal(true));
      return false;
    }

    dispatch(
        stripeAccountActions.setUpdateStripeAccountLink(
          resultAction.payload.accountLink.url
        )
      );
      window.location.href = resultAction.payload.accountLink.url
    return true;
  };
  return { onFindStripeAccount, onCreateStripeAccount,onGetUpdateAccountLink };
}
