import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TMenuSliceState } from "./types/TMenuSliceState";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import { IMenuItemImageEntity } from "../../../../../common/Entities/IMenuItemImageEntity";
import { IMenuItemAllergenEntity } from "../../../../../common/Entities/IMenuItemAllergenEntity";

// Utility function to serialize dates
const serializeDate = (value: any): string | null => {
  if (value instanceof Date) {
    return value.toISOString();
  }
  return value ? String(value) : null;
};

const initialState: TMenuSliceState = {
  selectedMenu: null,
  isCreateMenuModalOpen: false,
  menuList: [],
  isUpdateMenuTitleModalOpen: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setSelectedMenu: (state, action: PayloadAction<IMenuEntity | null>) => {
      const menu = action.payload;
      state.selectedMenu = menu
        ? {
            ...menu,
            activeFrom: serializeDate(menu.activeFrom),
            activeTo: serializeDate(menu.activeTo),
          }
        : null;
    },
    setIsCreateMenuModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateMenuModalOpen = action.payload;
    },
    setIsUpdateMenuTitleModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateMenuModalOpen = action.payload;
    },
    setMenuList: (state, action: PayloadAction<IMenuEntity[]>) => {
      state.menuList = action.payload.map((menu) => ({
        ...menu,
        activeFrom: serializeDate(menu.activeFrom),
        activeTo: serializeDate(menu.activeTo),
      }));
    },
    addMenu: (state, action: PayloadAction<IMenuEntity>) => {
      const menu = action.payload;
      state.menuList = [
        {
          ...menu,
          activeFrom: serializeDate(menu.activeFrom),
          activeTo: serializeDate(menu.activeTo),
        },
        ...state.menuList,
      ];
    },
    deleteMenu: (state, action: PayloadAction<number>) => {
      state.menuList = state.menuList.filter(
        (menu) => menu.id !== action.payload
      );
    },
    updateMenu: (state, action: PayloadAction<IMenuEntity>) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.id
          ? {
              ...menu,
              ...action.payload,
              activeFrom: serializeDate(action.payload.activeFrom),
              activeTo: serializeDate(action.payload.activeTo),
            }
          : menu
      );
    },
    updateMenuCategory: (
      state,
      action: PayloadAction<{ category: IMenuCategoryEntity }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.category.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.category.id
                  ? { ...cat, name: action.payload.category.name }
                  : cat
              ),
            }
          : menu
      );
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === action.payload.category.menuId
        );
        console.log("foundMennnnnnaaaaaaaaaaaaaaaaaaa", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    deleteMenuItem: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        itemId: number;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.filter(
                        (menuItem) => menuItem.id !== action.payload.itemId
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === action.payload.menuId
        );
        console.log("foundMennnnnnaaaaaaaaaaaaaaaaaaa", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    createMenuCategory: (
      state,
      action: PayloadAction<{
        category: IMenuCategoryEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.category.menuId
          ? {
              ...menu,
              categories: [...menu.categories, action.payload.category],
            }
          : menu
      );
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === action.payload.category.menuId
        );
        console.log("foundMennnnnnaaaaaaaaaaaaaaaaaaa", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    deleteMenuCategory: (
      state,
      action: PayloadAction<{
        category: IMenuCategoryEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.category.menuId
          ? {
              ...menu,
              categories: menu.categories.filter(
                (category) => category.id !== action.payload.category.id
              ),
            }
          : menu
      );
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === action.payload.category.menuId
        );
        console.log("foundMennnnnnaaaaaaaaaaaaaaaaaaa", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },

    createMenuItem: (
      state,
      action: PayloadAction<{
        category: IMenuCategoryEntity;
        newItem: IMenuItemEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.category.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.category.id
                  ? {
                      ...cat,
                      itemList: [...cat.itemList, action.payload.newItem],
                    }
                  : cat
              ),
            }
          : menu
      );
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === action.payload.category.menuId
        );
        console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    createMenuItemSupplement: (
      state,
      action: PayloadAction<{
        supplement: IMenuSupplementEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) => {
        // Check if this menu contains the category with the item ID
        const updatedCategories = menu.categories.map((cat) => {
          if (cat.itemList.some((item) => item.id === action.payload.supplement.itemId)) {
            // Item found, update the itemList
            return {
              ...cat,
              itemList: cat.itemList.map((item) =>
                item.id === action.payload.supplement.itemId
                  ? { ...item, supplements:[...item.supplements,action.payload.supplement]} // Update item
                  : item
              ),
            };
          }
          return cat; // Return unchanged category
        });
      
        return {
          ...menu,
          categories: updatedCategories,
        };
      });
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === state.selectedMenu?.id
        );
        console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    updateMenuItemAllergenList: (
      state,
      action: PayloadAction<{
        menuItemAllergenList: IMenuItemAllergenEntity[];
      }>
    ) => {
      state.menuList = state.menuList.map((menu) => {
        // Check if this menu contains the category with the item ID
        const updatedCategories = menu.categories.map((cat) => {
          if (cat.itemList.some((item) => item.id === action.payload.menuItemAllergenList[0].menuItemId)) {
            // Item found, update the itemList
            return {
              ...cat,
              itemList: cat.itemList.map((item) =>
                item.id === action.payload.menuItemAllergenList[0].menuItemId
                  ? { ...item, menuItemAllergen:action.payload.menuItemAllergenList} // Update item
                  : item
              ),
            };
          }
          return cat; // Return unchanged category
        });
      
        return {
          ...menu,
          categories: updatedCategories,
        };
      });
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === state.selectedMenu?.id
        );
        console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    deleteMenuItemSupplement: (
      state,
      action: PayloadAction<{
        supplementId: number;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) => {
        // Iterate through the menus
        const updatedCategories = menu.categories.map((cat) => {
          // Check if the category contains the supplement to delete
          if (cat.itemList.some((item) =>
              item.supplements?.some((supplement) => supplement.id === action.payload.supplementId)
            )
          ) {
            return {
              ...cat,
              itemList: cat.itemList.map((item) => ({
                ...item,
                supplements: item.supplements?.filter(
                  (supplement) => supplement.id !== action.payload.supplementId
                ),
              })),
            };
          }
          return cat; // Return unchanged category
        });
      
        return {
          ...menu,
          categories: updatedCategories,
        };
      });
      
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === state.selectedMenu?.id
        );
        console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    removeFromMenuItemAllergenList: (
      state,
      action: PayloadAction<{
        menuItemAllergenId: number;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) => {
        // Iterate through the menus
        const updatedCategories = menu.categories.map((cat) => {
          // Check if the category contains the supplement to delete
          if (cat.itemList.some((item) =>
              item.menuItemAllergen?.some((allergen) => allergen.id === action.payload.menuItemAllergenId)
            )
          ) {
            return {
              ...cat,
              itemList: cat.itemList.map((item) => ({
                ...item,
                menuItemAllergen: item.menuItemAllergen?.filter(
                  (allergen) => allergen.id !== action.payload.menuItemAllergenId
                ),
              })),
            };
          }
          return cat; // Return unchanged category
        });
      
        return {
          ...menu,
          categories: updatedCategories,
        };
      });
      
      //Update selectedMenu
      if (state.selectedMenu) {
        const foundMenu = state.menuList.find(
          (menu) => menu.id === state.selectedMenu?.id
        );
        console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
        state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
      }
    },
    
    updateMenuItem: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        item: IMenuItemEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.map((menuItem) =>
                        menuItem.id === action.payload.item.id
                          ? { ...menuItem, ...action.payload.item }
                          : menuItem
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
            //Update selectedMenu
            if (state.selectedMenu) {
              const foundMenu = state.menuList.find(
                (menu) => menu.id === state.selectedMenu?.id
              );
              console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
              state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
            }
    },
    updateMenuSupplementList: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        itemId: number;
        newSupplementList: IMenuSupplementEntity[];
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.map((menuItem) =>
                        menuItem.id === action.payload.itemId
                          ? {
                              ...menuItem,
                              supplements: action.payload.newSupplementList,
                            }
                          : menuItem
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
    },

    

    updateMenuItemImage: (
      state,
      action: PayloadAction<{
        newImageUrl: string | null;
        menuItemId: number;
      }>
    ) => {
      // Create a new menuList to avoid mutating the state
      state.menuList = state.menuList.map((menu) => ({
        ...menu,
        categories: menu.categories.map((category) => ({
          ...category,
          itemList: category.itemList.map((item) =>
            item.id === action.payload.menuItemId
              ? {
                  ...item,
                  menuItemImage: {
                    ...item.menuItemImage,
                    image: {
                      ...item.menuItemImage.image,
                      url: action.payload.newImageUrl,
                    },
                  },
                }
              : item
          ),
        })),
      }));
            //Update selectedMenu
            if (state.selectedMenu) {
              const foundMenu = state.menuList.find(
                (menu) => menu.id === state.selectedMenu?.id
              );
              console.log("new menuuuuuuuuuuuuuuuuuuuuu", foundMenu);
              state.selectedMenu = foundMenu ? foundMenu : state.selectedMenu;
            }
    },

    syncMenu: (state) => {
      if (state.selectedMenu) {
        const updatedMenu = state.menuList.find(
          (menu) => menu.id === state.selectedMenu?.id
        );
        if (updatedMenu) {
          state.selectedMenu = {
            ...updatedMenu,
            activeFrom: serializeDate(updatedMenu.activeFrom),
            activeTo: serializeDate(updatedMenu.activeTo),
          };
        } else {
          // If the selected menu is no longer in the list, reset it
          state.selectedMenu = null;
        }
      }
    },
  },
});

export const menuReducer = menuSlice.reducer;
export const menuActions = menuSlice.actions;
