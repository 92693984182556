import React, { useState } from "react";
import { TEditableCategoryTitleContainerProps } from "./types/TEditableCategoryTitleContainerProps";
import EditableTitle from "../../../Components/EditableTitle/EditableTitle";
import { Box, Typography } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import useMenuCategoryRepository from "../../../../../Menu/modules/category/containers/repository/useMenuCategoryRepository";
import ConfirmationModal from "../../../../../../common/components/ConfirmationModal/ConfirmationModal";
import DeleteMenuCategoryConfirmationModal from "../../../Modals/DeleteMenuCategoryConfirmationModal/DeleteMenuCategoryConfirmationModal";

export default function EditableCategoryTitleContainer(
  props: TEditableCategoryTitleContainerProps
) {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const { onDeleteMenuCategory, onUpdateMenuCategory } =
    useMenuCategoryRepository({});
  const handleDelete = async () => {
    await onDeleteMenuCategory({ category: props.category });

    props.index - 1 > 0
      ? props.setSelectedIndex(props.index - 1)
      : props.setSelectedIndex(99999999);
  };
  const handleUpdate = async (newName: string) => {
    await onUpdateMenuCategory({
      id: props.category.id!,
      name: newName,
      position: props.category.position,
      menuId: props.category.menuId,
    });
  };
  const isSelected = props.selectedIndex === props.index;

  const handleCloseDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  return (
    <Box
      key={props.key}
      sx={{
        position: "relative", // Enable absolute positioning for children
        backgroundColor: isSelected ? "#FBBC04" : "",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderRadius: "17px",
        cursor: "pointer",
        display: "flex", // Keeps title and content aligned vertically
        alignItems: "center",
        marginRight: "8px",
      }}
    >
      <DeleteMenuCategoryConfirmationModal
        category={props.category}
        isOpen={isDeleteConfirmationModalOpen}
        handleClose={handleCloseDeleteConfirmationModal}
        handleDelete={handleDelete}
      />
      {isSelected ? (
        <>
          <EditableTitle
            title={props.category.name}
            onUpdateTitle={handleUpdate}
            style={{
              fontSize: "16px",
              color: "white",
              fontWeight: "500",
            }}
          />
          <Box
            onClick={handleOpenDeleteConfirmationModal}
            sx={{
              position: "absolute", // Position the icon absolutely
              right: "10px", // Offset from the right edge of the container
              top: "50%", // Vertically center the icon
              transform: "translateY(-50%)", // Adjust for true vertical centering
              cursor: "pointer",
              color: props.selectedIndex === props.index ? "white" : "#383838",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DeleteOutlined />
          </Box>
        </>
      ) : (
        <Typography
          sx={{
            fontSize: "14px",
            color: "#383838",
            fontWeight: "500",
            "&::first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {props.category.name}
        </Typography>
      )}
    </Box>
  );
}
