import React from "react";
import { TMenuTitleWithTimeframeProps } from "./types/TMenuTitleWithTimeframeProps";
import { Typography } from "@mui/material";

export default function MenuTitleWithTimeframe(
  props: TMenuTitleWithTimeframeProps
) {
  const formatDateTime = (date: Date) => {
    if (!date) return "";
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const showMenuBoxTimeframeText = (
    activeFrom: Date | null,
    activeTo: Date | null
  ): string => {
    if (activeFrom && activeTo) {
      return `(${formatDateTime(activeFrom)} - ${formatDateTime(activeTo)})`;
    }
    return "(Toute la journée)";
  };
  return (
    <Typography
      sx={{ color: "#383838", fontSize: "25px", fontWeight: "600" }}
      className="font-barlow"
    >
      {props.name}{" "}
      <span style={{ fontSize: "10px", color: "#686868", fontWeight: "500" }}>
        {showMenuBoxTimeframeText(props.activeFrom, props.activeTo)}
      </span>
    </Typography>
  );
}
