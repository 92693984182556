// src/hooks/useMenuData.js
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { selectSelectedMenu } from "../../../../Menu/modules/menu/selectors/menuSelector";
import { fetchStat } from "../../../../../utils/sharedFunctions";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { categoryActions } from "../../../../Menu/modules/category/slice/categorySlice";
import { LoadingStatesEnum } from "../../../../../common/enums/LoadingStatesEnum";
import { itemActions } from "../../../../Menu/modules/item/slice/itemSlice";
import useMenuRepository from "../../../../../common/new/repositories/useMenuRepository";

const useMenuDataRepository = (
  menuId: string | undefined,
  categoryId: string | undefined,
  itemId: string | undefined
) => {
  const dispatch = useAppDispatch();
  const selectedMenu = useAppSelector(selectSelectedMenu);
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  const selectedItem = useAppSelector((state) => state.item.selectedItem);
  const [menuLoading, setMenuLoading] = useState(LoadingStatesEnum.IDLE);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { onFindMenu } = useMenuRepository();

  const handleFindMenu = (menuIdOption: any) => {
    fetchStat(onFindMenu, setMenuLoading, { menuId: menuIdOption });
  };

  const handleUpdateCategory = (menu: IMenuEntity, id: number) => {
    const foundCategory = menu.categories.find((cat) => cat.id === id);
    if (foundCategory) {
      dispatch(categoryActions.setSelectedCategory(foundCategory));
    }
  };

  const handleUpdateItem = (category: IMenuCategoryEntity, id: number) => {
    const foundItem = category.itemList.find((item) => item.id === id);
    console.log("foundItem", foundItem);
    if (foundItem) {
      dispatch(itemActions.setSelectedItem(foundItem));
    }
  };

  // Effect to fetch menu
  useEffect(() => {
    if (!selectedMenu && menuId) {
      handleFindMenu(menuId);
    }
  }, [selectedMenu, menuId]);

  // Effect to set selected category
  useEffect(() => {
    if (selectedMenu) {
      if (selectedCategory) {
        handleUpdateCategory(selectedMenu, selectedCategory.id!);
      }
      if (!selectedCategory && categoryId) {
        handleUpdateCategory(selectedMenu, parseInt(categoryId));
      }
    }
  }, [selectedMenu, categoryId]);

  // Effect to set selected item
  useEffect(() => {
    if (selectedMenu && selectedCategory) {
      console.log("s2222222222222222222222222222");
      if (selectedItem) {
        console.log("s3333333333333333333333333333");
        handleUpdateItem(selectedCategory, selectedItem.id!);
      }
      if (!selectedItem && itemId) {
        handleUpdateItem(selectedCategory, parseInt(itemId));
      }
    }
  }, [selectedMenu, selectedCategory, itemId]);

  return { selectedMenu, selectedCategory, selectedItem, menuLoading };
};

export default useMenuDataRepository;
