import React, { useState } from "react";
import MoreVerticalMenu from "../../../../../../common/components/MoreVerticalMenu/MoreVerticalMenu";
import MoreVerticalMenuButton from "../../../../../../common/components/MoreVerticalMenuButton/MoreVerticalMenuButton";
import DeleteMenuConfirmationModal from "../../../../../Menu/modules/menu/containers/Modals/DeleteMenuConfirmationModal";
import UpdateMenuModal from "../../../../../Menu/modules/menu/containers/Modals/UpdateMenuModal";
import { TMoreVerticalMenuContainerProps } from "./types/TMoreVerticalMenuContainerProps";

export default function MoreVerticalMenuContainer(
  props: TMoreVerticalMenuContainerProps
) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const handleDeleteMenu = () => {
    setIsDeleteModalOpen(true);
  };

  const handleUpdateMenu = () => {
    setIsUpdateModalOpen(true);
  };
  return (
    <MoreVerticalMenu>
      <DeleteMenuConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        selectedMenu={props.menu}
      />
      <UpdateMenuModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        selectedMenu={props.menu}
      />
      <MoreVerticalMenuButton onClick={handleUpdateMenu} title="Modifier" />
      <MoreVerticalMenuButton onClick={handleDeleteMenu} title="Supprimer" />
    </MoreVerticalMenu>
  );
}
