import React from 'react'
import { TCloseIconAllergenProps } from './types/TCloseIconAllergenProps'

export default function CloseIconAllergen(props:TCloseIconAllergenProps) {
  return (
    <svg width={`${props.width ? props.width : 20}`} height={`${props.height ? props.height : 20}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2478_3665)">
<circle cx="10" cy="6" r="6" fill="white"/>
</g>
<path d="M7.93761 8.18718L12.0624 4.06239M7.93761 4.06239L12.0624 8.18718" stroke="#FBBC04" stroke-width="1.2" stroke-linecap="round"/>
<defs>
<filter id="filter0_d_2478_3665" x="0" y="0" width="20" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2478_3665"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2478_3665" result="shape"/>
</filter>
</defs>
</svg>

  )
}
