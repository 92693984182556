import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../common/enums/LoadingStatesEnum";
import CheckIconAnimated from "../../Components/Fields/CheckIconAnimated/CheckIconAnimated";
import { TuseAutoSaveFieldProps } from "./types/TuseAutoSaveFieldProps";

export default function useAutoSaveField(props: TuseAutoSaveFieldProps) {
  const [showSuccessAnimation, setShowSuccessAnimation] =
    useState<boolean>(false);
  const [showConfirmationBox, setShowConfirmationBox] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.loadingState === LoadingStatesEnum.SUCCESS) {
      setShowSuccessAnimation(true);

      const timer = setTimeout(() => {
        setShowSuccessAnimation(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [props.loadingState]);

  const handleSaveValue = () => {
    props.onSaveValue(props.inputValue);
    setShowConfirmationBox(false);
  };
  const handlePressKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      const inputElement = event.currentTarget.querySelector("input");
      if (inputElement) {
        inputElement.blur();
      }
    }
  };
  const showEndAdornment = () => {
    if (props.loadingState === LoadingStatesEnum.LOADING) {
      return <CircularProgress size={20} />;
    }
    if (props.loadingState === LoadingStatesEnum.SUCCESS) {
      return <CheckIconAnimated showAnimation={showSuccessAnimation} />;
    }
  };
  const handleKeepModifying = () => {
    setShowConfirmationBox(false);
    //Focus back on the element
  };
  const handleResetField = () => {
    props.setInputValue(props.defaultValue || "");
    setShowConfirmationBox(false);
  };

  return {
    handleKeepModifying,
    handleResetField,
    showEndAdornment,
    handlePressKeyDown,
    handleSaveValue,
    showSuccessAnimation,
    showConfirmationBox,
    setShowConfirmationBox,
    setShowSuccessAnimation,
  };
}
