import { Box, Typography } from "@mui/material";
import "../../../../common/containers/styles/customFonts.css";
import { ReactComponent as LogoutIcon } from "../../../../common/images/sidebar_lougout_icon.svg";
import useAuthRepository from "../../repository/useAuthRepository";

export default function SignoutButton() {
  const { onSignOut } = useAuthRepository();
  const handleSignout = async () => {
    await onSignOut();
  };
  return (
    <Box sx={{ cursor: "pointer" }}>
      <LogoutIcon
        onClick={() => {
          handleSignout();
        }}
      />
    </Box>
  );
}
