import React from "react";
import { TGetMenuRequest } from "../../../modules/Menu/modules/menu/service/Requests/TGetMenuRequest";
import { useAppDispatch } from "../../store/redux";
import { getMenu } from "../../../modules/Menu/modules/menu/service/menusServices";
import { IMenuEntity } from "../../Entities/IMenuEntity";
import { menuActions } from "../../../modules/Menu/modules/menu/slice/menuSlice";

export default function useMenuRepository() {
  const dispatch = useAppDispatch();

  const onFindMenu = async (requestData: TGetMenuRequest): Promise<boolean> => {
    const resultAction = await dispatch(getMenu(requestData));

    if (getMenu.rejected.match(resultAction)) {
      return false;
    }
    const foundMenu: IMenuEntity = resultAction.payload.menu;
    dispatch(menuActions.setSelectedMenu(foundMenu));
    return true;
  };
  return { onFindMenu };
}
