import React, { useEffect } from "react";
import { TItemImageEditorContainerProps } from "./types/TItemImageEditorContainerProps";
import { Box, Grid, Typography } from "@mui/material";
import ImageUploadBox from "../../../Components/ImageUploadBox/ImageUploadBox";
import { FileStateEnum } from "../../../../../../common/enums/FileStateEnum";
import useMenuItemImageRepository from "../../../../../../common/new/repositories/useMenuItemImageRepository";
import useImageUpload from "../../../Hooks/useImageUpload/useImageUpload";

export default function ItemImageEditorContainer(
  props: TItemImageEditorContainerProps
) {
  const {
    file,
    image,
    handleUploadFile,
    handleDeleteImage,
    fileState,
    fileUploadError,
  } = useImageUpload({
    defaultImage: props.previousItemImage.image.url,
  });

  const {
    onUpdateMenuItemImage,
    onDeleteMenuItemImage,
    onDisplayErrorMessage,
  } = useMenuItemImageRepository();

  useEffect(() => {
    switch (fileState) {
      case FileStateEnum.TO_DELETE: {
        onDeleteMenuItemImage(props.previousItemImage);
        break;
      }
      case FileStateEnum.TO_UPLOAD: {
        file &&
          image &&
          onUpdateMenuItemImage(props.previousItemImage, file, image);
        break;
      }
      case FileStateEnum.ERROR: {
        fileUploadError && onDisplayErrorMessage(fileUploadError.message);
        break;
      }
      default: {
        break;
      }
    }
  }, [fileState, file, image]);
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography
          className="font-sen"
          sx={{ fontSize: "13px", color: "black", textTransform: "uppercase" }}
          mb={2}
        >
          CHARGER UNE IMAGE
        </Typography>
      </Grid>
      <Box sx={{ width: "111px", height: "101px" }}>
        <ImageUploadBox
          image={image}
          handleUploadFile={handleUploadFile}
          handleDeleteImage={handleDeleteImage}
        />
      </Box>
    </Grid>
  );
}
