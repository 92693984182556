import React from "react";
import { TMultilineLoadingManagementProps } from "./types/TMultilineLoadingManagementProps";
import { Box, CircularProgress } from "@mui/material";
import { LoadingStatesEnum } from "../../../../../../../common/enums/LoadingStatesEnum";
import CheckIconAnimated from "../../CheckIconAnimated/CheckIconAnimated";

export default function MultilineLoadingManagement(
  props: TMultilineLoadingManagementProps
) {
  return (
    <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
      {props.loadingState === LoadingStatesEnum.LOADING && (
        
          <CircularProgress />
        
      )}
      {props.loadingState === LoadingStatesEnum.SUCCESS && (
        <CheckIconAnimated showAnimation={props.showSuccessAnimation} />
      )}
    </Box>
  );
}
