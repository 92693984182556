import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { categoryActions } from "../../../../../Menu/modules/category/slice/categorySlice";
import EditableNavigationBar from "../../../Components/EditableNavigationBar/EditableNavigationBar";
import EditButtonOrange from "../../../Components/EditButtonOrange/EditButtonOrange";
import MenusNavigationBar from "../../../Components/MenusNavigationBar/MenusNavigationBar";
import CreateCategoryContainer from "../CreateCategoryButtonContainer/CreateCategoryContainer";
import EditableCategoryTitleContainer from "../EditableCategoryTitleContainer/EditableCategoryTitleContainer";
import { TEditableNavigationBarContainerProps } from "./types/TEditableNavigationBarContainerProps";

export default function EditableNavigationBarContainer(
  props: TEditableNavigationBarContainerProps
) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  const getCategoryNames = (menu: IMenuEntity): string[] => {
    return menu.categories.map((category) => category.name);
  };
  const getEditableCategoryNames = (menu: IMenuEntity): JSX.Element[] => {
    return menu.categories.map((category, index) => (
      <EditableCategoryTitleContainer
        category={category}
        index={index}
        key={category.id!}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    ));
  };

  useEffect(() => {
    const foundIndex = props.menu.categories.findIndex(
      (c) => c.id === props.defaultSelectedCategoryId
    );
    console.log(
      "foundiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",
      foundIndex
    );
    if (foundIndex >= 0) {
      setSelectedIndex(foundIndex);
    } else {
      props.menu.categories.length > 0 && setSelectedIndex(0);
    }
  }, [props.defaultSelectedCategoryId]);

  useEffect(() => {
    if (selectedIndex !== null) {
      if (props.menu.categories.length < 0) {
        dispatch(categoryActions.setSelectedCategory(null));
      } else {
        const foundCategory = props.menu.categories.find(
          (category, index) => index === selectedIndex
        );
        console.log("selectedIndex changed", selectedIndex);
        console.log(
          "foundCategoryfoundCategoryfoundCategoryfoundCategory",
          foundCategory
        );
        foundCategory
          ? dispatch(categoryActions.setSelectedCategory(foundCategory))
          : dispatch(
              categoryActions.setSelectedCategory(props.menu.categories[0])
            );
      }
    }
  }, [selectedIndex]);

  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  useEffect(() => {
    if (selectedCategory !== null && selectedIndex !== null) {
      const foundCategoryIndex = props.menu.categories.findIndex(
        (category) => category.id === selectedCategory.id
      );
      console.log("chanpppppppppppppppppppppppppppppppp", foundCategoryIndex);
      if (foundCategoryIndex !== -1 && foundCategoryIndex !== selectedIndex) {
        setSelectedIndex(foundCategoryIndex);
      }
    }
  }, [JSON.stringify(selectedCategory)]);

  return (
    <Box sx={{ alignItems: "center", gap: 2, display: "flex", width:"fit-content",maxWidth:"100%" }}>
      {props.menu.categories.length > 0 ? (
        <>
          {isEditing ? (
           
              <EditableNavigationBar
                editableTitleList={getEditableCategoryNames(props.menu)}
                setIsEditing={setIsEditing}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
          
          ) : (
         
              <MenusNavigationBar
                nameList={getCategoryNames(props.menu)}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
         
          )}
          <EditButtonOrange
            onClick={() => {
              setIsEditing(true);
            }}
          />
        </>
      ) : (
        <>
          <Typography>Ajouter une catégorie</Typography>
        </>
      )}

      <CreateCategoryContainer menu={props.menu} />
    </Box>
  );
}
