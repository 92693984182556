import { useAppDispatch } from "../../../../../../common/store/redux";
import CreateMenuModal from "../../../../../Menu/modules/menu/containers/Modals/CreateMenuModal";
import { menuActions } from "../../../../../Menu/modules/menu/slice/menuSlice";
import AddButtonOrange from "../../../Components/AddButtonOrange/AddButtonOrange";
import { TCreateMenuButtonContainerProps } from "./types/TCreateMenuButtonContainerProps";

export default function CreateMenuButtonContainer(
  props: TCreateMenuButtonContainerProps
) {
  const dispatch = useAppDispatch();
  const handleOpenCreateMenuModal = () => {
    dispatch(menuActions.setIsCreateMenuModalOpen(true));
  };
  return (
    <div>
      <CreateMenuModal menuList={props.menuList} />
      <AddButtonOrange onClick={handleOpenCreateMenuModal} />
    </div>
  );
}
