import CancelIcon from "@mui/icons-material/Cancel";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TSupplementTableProps } from "./types/TSupplementTableProps";
import { LoadingStatesEnum } from "../../../../../common/enums/LoadingStatesEnum";

export default function SupplementTable(props: TSupplementTableProps) {
  return (
    <TableContainer
      sx={{
        border: "2px solid rgba(0,0,0,0.5)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Table
        aria-label="supplement table"
        sx={{ borderCollapse: "separate", borderSpacing: 0 }}
      >
        <TableHead>
          <TableRow>
            {["Nom Garniture", "Qty/max", "Prix/Unité", "Action"].map(
              (header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "14px",
                    borderBottom: "2px solid rgba(0,0,0,0.5)",
                    color: "#181818",
                    fontSize: "14px",
                  }}
                  className="font-sen"
                >
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.supplementList.map((supplement, supplementIndex) => (
            <TableRow key={supplement.id!}>
              {[
                supplement.name,
                supplement.maxQuantity,
                `€ ${supplement.price}`,
                "",
              ].map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  sx={{
                    textAlign: "center",
                    borderRight: `${cellIndex !== 3 && "2px solid rgba(0,0,0,0.2)"}`,
                    borderBottom: `${supplementIndex !== props.supplementList.length - 1 && "2px solid rgba(0,0,0,0.2)"}`,
                    color: `${cellIndex === 2 ? "#091E42" : "black"}`,
                    fontSize: "14px",
                    fontWeight: cellIndex === 0 ? "bold" : "200",
                  }}
                  className="font-sen"
                >
                  {cellIndex === 3 ? (
                    <CancelIcon
                      onClick={() => {
                        props.loadingState !== LoadingStatesEnum.LOADING &&
                          props.onDelete(supplement.id!);
                      }}
                      sx={{
                        cursor: "pointer",
                        color:
                          props.loadingState === LoadingStatesEnum.LOADING
                            ? "rgba(251, 188, 4,0.5)"
                            : "rgba(251, 188, 4,1)",
                      }}
                    />
                  ) : (
                    cell
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
