import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { categoryActions } from "../../../../../Menu/modules/category/slice/categorySlice";
import MenusNavigationBar from "../../../Components/MenusNavigationBar/MenusNavigationBar";
import { TCategoryNavigationBarContainerProps } from "./types/TCategoryNavigationBarContainerProps";
import { useNavigate } from "react-router-dom";

export default function CategoryNavigationBarContainer(
  props: TCategoryNavigationBarContainerProps
) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    const defaultIndex = props.menu.categories.findIndex(
      (c) => c.id === props.defaultCategory.id
    );

    if (defaultIndex >= 0) {
      setSelectedIndex(defaultIndex);
    }
  }, []);
  const dispatch = useAppDispatch();

  const getCategoryNames = (menu: IMenuEntity): string[] => {
    return menu.categories.map((category) => category.name);
  };

  const navigate = useNavigate();
  const handleClick = (index:number) => {
    navigate(`/dashboard/menus/${props.menu.id!}/${props.menu.categories[index].id!}`);
  };

  return (
    <Grid container direction={"row"} sx={{ alignItems: "center", gap: 2 }}>
      <MenusNavigationBar
        nameList={getCategoryNames(props.menu)}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        handleClick={handleClick}
        menu={props.menu}
      />
    </Grid>
  );
}
