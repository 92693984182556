import { Box, Typography } from "@mui/material";
import { TToolTipOverlayProps } from "./types/TToolTipOverlayProps";

export default function ToolTipOverlay(props: TToolTipOverlayProps) {
  return (
    <Box
      position="absolute"
      left={`${props.tooltipData.x}px`}
      top={`${props.tooltipData.y - 30}px`}
      sx={{
        transform: "translateX(-50%)",
        backgroundColor: "#FBBC04",
        padding: "5px 10px",
        borderRadius: "4px",
        textAlign: "center",
        pointerEvents: "none",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {props.tooltipData.value}
      </Typography>
    </Box>
  );
}
