import {
  combineReducers,
  configureStore,
  createAction,
  Reducer,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import { headerReducer } from "./headerSlice";
import { authReducer } from "../../modules/Auth/slices/authSlice";
import { customFormReducer } from "./customFormSlice";
import { accountReducer } from "../../modules/Account/slices/accountSlice";
import { stripeAccountReducer } from "../../modules/Account/modules/StripeAccount/slices/stripeAccountSlice";
import { cartReducer } from "../../modules/Cart/slices/cartSlice";
import { paymentReducer } from "../../modules/Payment/slices/paymentSlice";
import { appReducer } from "./appSlice";
import { fundsManagementReducer } from "../../modules/Account/modules/FundsManagement/slices/fundsManagementSlice";
import { reviewsReducer } from "../../modules/Reviews/slices/reviewsSlices";
import { menuReducer } from "../../modules/Menu/modules/menu/slice/menuSlice";
import { categoryReducer } from "../../modules/Menu/modules/category/slice/categorySlice";
import { itemReducer } from "../../modules/Menu/modules/item/slice/itemSlice";
import { supplementReducer } from "../../modules/Menu/modules/supplements/slices/supplementSlice";
import { menuItemAllergenReducer } from "../../modules/Menu/modules/allergens/slices/menuItemAllergen";
import { menuAllergenReducer } from "../../modules/Menu/modules/allergens/slices/menuAllergen";
import { menuPreviewReducer } from "../../modules/MenuPreview/slices/menuPreviewSlice";
import { imageReducer } from "../../modules/Menu/modules/image/slices/imageSlice";
import { iamReducer } from "../../modules/IAM/slices/iamSlices";
import { rolesReducer } from "../../modules/Roles/slice/roleSlice";
import { kitchenReducer } from "../../modules/Kitchen/slice/kitchenSlice";
import { notificationReducer } from "../modules/Notifications/slices/notificationSlice";
import { menuItemCustomizationReducer } from "../../modules/Menu/modules/itemCustomization/slice/menuItemCustomizationSlice";
import { cartSliceReducer } from "../new/slices/cartSlice";
import { reviewSliceReducer } from "../new/slices/reviewSlice";

const reducers = {
  header: headerReducer,
  auth: authReducer,
  form: customFormReducer,
  account: accountReducer,
  cart: cartReducer,
  stripeAccount: stripeAccountReducer,
  payment: paymentReducer,
  /*menuPreview: menuPreviewReducer,*/
  app: appReducer,
  fundsManagement: fundsManagementReducer,
  reviews: reviewsReducer,
  menu: menuReducer,
  category: categoryReducer,
  item: itemReducer,
  supplement: supplementReducer,
  menuItemAllergen: menuItemAllergenReducer,
  menuAllergen: menuAllergenReducer,
  menuPreview: menuPreviewReducer,
  image: imageReducer,
  iam: iamReducer,
  roles: rolesReducer,
  kitchen: kitchenReducer,
  notification: notificationReducer,
  menuItemCustomization: menuItemCustomizationReducer,
  //New
  cartSlice: cartSliceReducer,
  reviewSlice: reviewSliceReducer,
};

export type RootState = StateFromReducersMapObject<typeof reducers>;
const combinedReducer: Reducer<RootState> = combineReducers(reducers);

const rootReducer = (
  state: RootState | undefined,
  action: {
    type: "resetStore";
  }
) => {
  if (action.type === "resetStore") {
    // Resets state on resetStore e.g. logout
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export const resetStore = createAction("resetStore");
