import { Grid } from "@mui/material";
import { useAppSelector } from "../../../../../../common/store/redux";
import MenuItemCard from "../../../Components/MenuItemCard/MenuItemCard";
import { TDisplayItemListContainerProps } from "./types/TDisplayItemListContainerProps";

export default function DisplayItemListContainer(
  props: TDisplayItemListContainerProps
) {
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );

  return selectedCategory ? (
    <Grid container mt={"28px"} direction={"row"} sx={{ gap: 4 }}>
      {selectedCategory.itemList.length > 0 &&
        selectedCategory.itemList.map((item, index) => {
          return (
            <Grid item key={item.id!}>
              <MenuItemCard menuItem={item} clickable={true} />
            </Grid>
          );
        })}
    </Grid>
  ) : (
    <>{/* Ajouter un écran de chargement*/}</>
  );
}
