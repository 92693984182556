import { Box, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import MenusNavigationBar from "../../../Components/MenusNavigationBar/MenusNavigationBar";
import DateAndNamePageTitleContainer from "../../DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";
import CreateMenuButtonContainer from "../CreateMenuButtonContainer/CreateMenuButtonContainer";
import DisplayMenuListContainer from "../DisplayMenuListContainer/DisplayMenuListContainer";
import { TMenuDashboardContainerProps } from "./types/TMenuDashboardContainerProps";
import { menuActions } from "../../../../../Menu/modules/menu/slice/menuSlice";

export default function MenuDashboardContainer(
  props: TMenuDashboardContainerProps
) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  let menuRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const scrollToMenu = (menuId: number) => {
    const element = menuRefs.current[menuId];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const dispatch = useAppDispatch();
  const getMenuNames = (menuList: IMenuEntity[]): string[] => {
    return menuList.map((menu) => menu.name);
  };

  useEffect(() => {
    if (selectedIndex === null) {
      setSelectedIndex(0);
    }
  }, []);

  useEffect(() => {
    if (selectedIndex !== null) {
      if (props.menuList.length < 0) {
        dispatch(menuActions.setSelectedMenu(null));
      } else {
        const foundMenu = props.menuList.find(
          (menu, index) => index === selectedIndex
        );
        if (foundMenu) {
          scrollToMenu(foundMenu.id!);
        } else {
          dispatch(menuActions.setSelectedMenu(props.menuList[0]));
        }
      }
    }
  }, [selectedIndex]);

  return props.menuList.length > 0 ? (
    <Grid container direction={"column"}>
      <DateAndNamePageTitleContainer />
      <Grid container direction={"row"} sx={{ alignItems: "center", gap: 2 }}>
        <Grid item maxWidth={"70%"}>
          <MenusNavigationBar
            nameList={getMenuNames(props.menuList)}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </Grid>
        <Grid item>
          <CreateMenuButtonContainer menuList={props.menuList} />
        </Grid>
      </Grid>

      <DisplayMenuListContainer menuList={props.menuList} menuRefs={menuRefs} />
    </Grid>
  ) : (
    <>{/** Add loading state management */}</>
  );
}
