import React from 'react'
import { useAppDispatch } from '../../store/redux';
import { TCreateMenuSupplementRequest } from '../../../modules/Menu/modules/supplements/services/types/Requests/TCreateMenuSupplementRequest';
import { createMenuSupplement, deleteMenuSupplement } from '../../../modules/Menu/modules/supplements/services/supplementService';
import { menuActions } from '../../../modules/Menu/modules/menu/slice/menuSlice';
import { IMenuSupplementEntity } from '../../Entities/IMenuSupplementEntity';
import { request } from 'http';
import { TDeleteMenuSupplementRequest } from '../../../modules/Menu/modules/supplements/services/types/Requests/TDeleteMenuSupplementRequest';

export default function useMenuItemSupplementRepository() {
    const dispatch = useAppDispatch();

  const onCreateSupplement = async (requestData: TCreateMenuSupplementRequest): Promise<boolean> => {
    const resultAction = await dispatch(createMenuSupplement(requestData));

    if (createMenuSupplement.rejected.match(resultAction)) {
      return false;
    }
    const createdSupplement: IMenuSupplementEntity = resultAction.payload;
    dispatch(menuActions.createMenuItemSupplement({supplement:createdSupplement}));
    return true;
  };
  const onDeleteSupplement = async (requestData: TDeleteMenuSupplementRequest): Promise<boolean> => {
    const resultAction = await dispatch(deleteMenuSupplement(requestData));

    if (deleteMenuSupplement.rejected.match(resultAction)) {
      return false;
    }

    dispatch(menuActions.deleteMenuItemSupplement({supplementId:requestData.supplementId}));
    return true;
  };
  return { onCreateSupplement,onDeleteSupplement };
}
