import { Box, Grid } from "@mui/material";

import UserProfileCardContainer from "../../containers/UserProfileCardContainer/UserProfileCardContainer";

import NotificationBell from "../NotificationBell/NotificationBell";

export default function DashboardHeader() {
  return (
    <Grid
      container
      sx={{
        width: "100%",

        backgroundColor: "transparent",
        zIndex: 999,
        right: 0,
        position: "relative",
        top: 0,
        justifyContent: "end",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <NotificationBell />
      <UserProfileCardContainer />
    </Grid>
  );
}
