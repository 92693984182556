import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCartSliceState } from "./types/TCartSliceState";
import { ICartEntity } from "../../Entities/ICartEntity";

const initialState: TCartSliceState = {
  carts: null,
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setCarts: (state, action: PayloadAction<ICartEntity[]>) => {
      state.carts = action.payload;
    },
  },
});

export const cartSliceReducer = cartSlice.reducer;
export const cartSliceActions = cartSlice.actions;
