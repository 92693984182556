import React from "react";
import { TConfirmationModalProps } from "./types/TConfirmationModalProps";
import { Box, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import CustomButton from "../ButtonOrange/CustomButton";

export default function ConfirmationModal(props: TConfirmationModalProps) {
  return (
    <MuiDialog open={props.isOpen} onClose={props.handleClose} maxWidth="sm">
      <DialogTitle
        sx={{ fontSize: "25px", color: "black", textAlign: "center" }}
        className="font-barlow"
      >
        {props.title}
      </DialogTitle>
      <Box
        sx={{
          marginBottom: "15px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          container
          direction="row"
          gap={4}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <CustomButton
            content={"Oui"}
            onClick={props.handleAgree}
            color={"#07D400"}
          />
          <CustomButton
            content={"Non"}
            color={"#FB0404"}
            onClick={() => {
              props.handleDisagree
                ? props.handleDisagree()
                : props.handleClose();
            }}
          />
        </Grid>
      </Box>
    </MuiDialog>
  );
}
