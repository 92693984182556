import React, { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useDailyOrderCardRepository from "../../../Repositories/HomePage/useDailyOrderCardRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import DailyOrderCard from "../../../Components/RightSidePanel/DailyOrderCard/DailyOrderCard";

export default function DailyOrderCardContainer() {
  const [todayOrdersNbrLoadingState, setTodayOrdersNbrLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.IDLE);

  const { onFindTodayOrdersNbr, todayOrdersNbr } =
    useDailyOrderCardRepository();

  useEffect(() => {
    if (todayOrdersNbr === null) {
      fetchStat(onFindTodayOrdersNbr, setTodayOrdersNbrLoadingState);
    }
  }, []);
  return (
    <DailyOrderCard
      orders={todayOrdersNbr ?? 0}
      loadingState={todayOrdersNbrLoadingState}
    />
  );
}
