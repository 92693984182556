import { Box } from "@mui/material";
import React, { useState } from "react";
import { TBlankImageUploadBoxProps } from "./types/TBlankImageUploadBoxProps";
import { PanoramaOutlined } from "@mui/icons-material";

export default function BlankImageUploadBox(props: TBlankImageUploadBoxProps) {
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <Box
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={() => {
        props.handleUploadFile();
      }}
      sx={{
        width: "100%",
        height: "100%",
        border: "1px dashed #ccc",
        backgroundColor: hovered ? "rgba(235,235,235,0.25)" : "",
        position: "relative",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <PanoramaOutlined
        sx={{ color: "rgba(0, 0, 0, 0.3)", width: "50%", height: "50%" }}
      />
    </Box>
  );
}
