import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import StatsBox from "../../../../../../common/components/StatsBox/StatsBox";
import { IPaymentEntity } from "../../../../../../common/Entities/IPaymentEntity";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import usePaymentRepository from "../../../../../../common/new/repositories/usePaymentRepository";
import { useAppSelector } from "../../../../../../common/store/redux";
import { fetchStat } from "../../../../../../utils/sharedFunctions";

export default function EarningBoxContainer() {
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );
  const formatEarning = (paymentList: IPaymentEntity[]): number => {
    return paymentList.reduce((previousBalance, payment) => {
      return previousBalance + parseFloat(`${payment.amount}`);
    }, 0);
  };
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const {onFindByUserRefAndDate} = usePaymentRepository();
  const payments = useAppSelector((state) => state.payment.payments);

  useEffect(() => {
    if (!payments) {
      fetchStat(onFindByUserRefAndDate, setLoadingState, {
        startDate: startDate,
        endDate: now,
      });
    }
  }, [payments]);
  return (
    <Grid container direction={"row"} sx={{ gap: "12px" }}>
      <StatsBox
        title="Revenus"
        stat={payments ? formatEarning(payments) : 0}
        loadingState={loadingState}
        currency={true}
      />
    </Grid>
  );
}
