import { Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useMenuDataRepository from "../../../Repositories/useMenuDataRepository/useMenuDataRepository";
import DateAndNamePageTitleContainer from "../../DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";
import ItemAllergenEditorContainer from "../ItemAllergenEditorContainer/ItemAllergenEditorContainer";
import ItemDescriptionFieldContainer from "../ItemDescriptionFieldContainer/ItemDescriptionFieldContainer";
import ItemPriceFieldContainer from "../ItemPriceFieldContainer/ItemPriceFieldContainer";
import ItemSupplementEditorContainer from "../ItemSupplementEditorContainer/ItemSupplementEditorContainer";
import ItemTitleFieldContainer from "../ItemTitleFieldContainer/ItemTitleFieldContainer";
import EditableNavigationBarContainer from "../../MenuEditorPage/EditableNavigationBarContainer/EditableNavigationBarContainer";
import CategoryNavigationBarContainer from "../CategoryNavigationBarContainer/CategoryNavigationBarContainer";
import ItemImageEditorContainer from "../ItemImageEditorContainer/ItemImageEditorContainer";

export default function MenuItemEditorContainer() {
  const { menuId, categoryId, itemId } = useParams();
  const { selectedMenu, selectedCategory, selectedItem, menuLoading } =
    useMenuDataRepository(menuId, categoryId, itemId);
  const navigate = useNavigate();
  /** Add loading state with menu loading */
  return menuLoading !== LoadingStatesEnum.LOADING &&
    selectedMenu &&
    selectedCategory &&
    selectedItem ? (
    <Grid container direction={"column"}>
      <DateAndNamePageTitleContainer />
      <CategoryNavigationBarContainer
        menu={selectedMenu}
        defaultCategory={selectedCategory}
      />

      <Grid
        container
        gap={4}
        sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
        mt={2}
      >
        <Grid
          item
          width={"50%"}
          sx={{ gap: 2, display: "flex", flexDirection: "column" }}
        >
          <ItemTitleFieldContainer menuItem={selectedItem} />
          <ItemDescriptionFieldContainer menuItem={selectedItem} />
          <ItemAllergenEditorContainer menuItem={selectedItem} />
          <ItemSupplementEditorContainer menuItem={selectedItem} />
        </Grid>
        <Grid
          item
          width={"50%"}
          sx={{ gap: 2, display: "flex", flexDirection: "column" }}
        >
          <Grid item>
            <ItemImageEditorContainer
              previousItemImage={selectedItem.menuItemImage}
            />
          </Grid>
          <ItemPriceFieldContainer menuItem={selectedItem} />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>{/**Loading state here */}</>
  );
}
