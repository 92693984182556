import React from "react";
import { TEditButtonOrangeProps } from "./types/TEditButtonOrangeProps";
import { ReactComponent as EditIcon } from "../../../../../common/images/edit_orange_icon.svg";
import { Box } from "@mui/material";

export default function EditButtonOrange(props: TEditButtonOrangeProps) {
  return (
    <Box onClick={props.onClick} sx={{ cursor: "pointer" }}>
      <EditIcon />
    </Box>
  );
}
