import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { menuActions } from "../../../menu/slice/menuSlice";
import {
  createMenuCategory,
  deleteMenuCategory,
  updateMenuCategory,
} from "../../service/categoryServices";
import { TCreateMenuCategoryRequest } from "../../service/type/Request/TCreateMenuCategoryRequest";
import { TDeleteMenuCategoryRequest } from "../../service/type/Request/TDeleteMenuCategoryRequest";
import { TUpdateMenuCategoryRequest } from "../../service/type/Request/TUpdateMenuCategoryRequest";
import { categoryActions } from "../../slice/categorySlice";
import { TuseMenuCategoryRepositoryProps } from "./types/TuseMenuCategoryRepositoryProps";

export default function useMenuCategoryRepository(
  props: TuseMenuCategoryRepositoryProps
) {
  const dispatch = useAppDispatch();
  const onUpdateMenuCategory = async (
    request: TUpdateMenuCategoryRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(updateMenuCategory(request));

    if (updateMenuCategory.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedMenuCategory: IMenuCategoryEntity = resultAction.payload;
    dispatch(
      menuActions.updateMenuCategory({
        category: updatedMenuCategory,
      })
    );
    return true;
  };

  const onDeleteMenuCategory = async (request: TDeleteMenuCategoryRequest) => {
    const resultAction = await dispatch(deleteMenuCategory(request));

    if (deleteMenuCategory.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(
      menuActions.deleteMenuCategory({
        category: request.category,
      })
    );

    return true;
  };

  const onCreateCategory = async (request: TCreateMenuCategoryRequest) => {
    const resultAction = await dispatch(createMenuCategory(request));

    if (createMenuCategory.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const createdCategory = resultAction.payload;
    dispatch(
      menuActions.createMenuCategory({
        category: createdCategory,
      })
    );
    dispatch(categoryActions.setSelectedCategory(createdCategory));

    return true;
  };

  return { onUpdateMenuCategory, onDeleteMenuCategory, onCreateCategory };
}
