import React, { useEffect } from "react";
import RHFTextField from "../../../../../../common/components/RHFTextField/RHFTextField";
import { TAddSupplementFieldsProps } from "./types/TAddSupplementFieldsProps";
import { useFormContext } from "react-hook-form";
import RHFNumberField from "../../../../../../common/components/RHFNumberField/RHFNumberField";

export default function AddSupplementFields(props: TAddSupplementFieldsProps) {
  const { watch } = useFormContext();
  const supplementName = watch("supplementName");
  const supplementMaxQuantity = watch("supplementMaxQuantity");
  const supplementPrice = watch("supplementPrice");

  useEffect(() => {
    if (
      !supplementName ||
      supplementName === "" ||
      !supplementMaxQuantity ||
      supplementMaxQuantity === "" ||
      !supplementPrice ||
      supplementPrice === ""
    ) {
      props.setAreAllFieldsFilled(false);
    } else if (supplementName && supplementMaxQuantity && supplementPrice) {
      console.log("dans le true");
      props.setAreAllFieldsFilled(true);
    }
  }, [supplementName, supplementMaxQuantity, supplementPrice]);
  return (
    <>
      <RHFTextField name="supplementName" placeholder="Nom" />
      <RHFNumberField name="supplementMaxQuantity" placeholder="Quantité" allowDecimals={false} />
      <RHFNumberField name="supplementPrice" placeholder="Prix" allowDecimals={true} decimalPlaces={2} />
    </>
  );
}
