import { useAppDispatch } from "../../../../../../common/store/redux";
import CreateMenuCategoryModal from "../../../../../Menu/modules/category/containers/Modals/CreateMenuCategoryModal";
import { categoryActions } from "../../../../../Menu/modules/category/slice/categorySlice";
import AddButtonOrange from "../../../Components/AddButtonOrange/AddButtonOrange";
import { TCreateCategoryButtonContainerProps } from "./types/TCreateCategoryButtonContainerProps";

export default function CreateCategoryContainer(
  props: TCreateCategoryButtonContainerProps
) {
  const dispatch = useAppDispatch();
  const handleOpenCreateMenuModal = () => {
    dispatch(categoryActions.setIsCreateCategoryModalOpen(true));
  };
  return (
    <div>
      <CreateMenuCategoryModal selectedMenu={props.menu} />
      <AddButtonOrange onClick={handleOpenCreateMenuModal} />
    </div>
  );
}
