import { Grid } from "@mui/material";
import { ReactComponent as FeedbackIconUnselected } from "../../../../common/images/sidebar_feedbacks_icon_unselected.svg";
import { ReactComponent as FeedbackIconSelected } from "../../../../common/images/sidebar_feedbacks_icon_selected.svg";
import { ReactComponent as MenuIconUnselected } from "../../../../common/images/sidebar_menu_icon_unselected.svg";
import { ReactComponent as MenuIconSelected } from "../../../../common/images/sidebar_menu_icon_selected.svg";
import { ReactComponent as HomeIconUnselected } from "../../../../common/images/sidebar_home_icon_unselected.svg";
import { ReactComponent as HomeIconSelected } from "../../../../common/images/sidebar_home_icon_selected.svg";
import SideBarButton from "./SideBarButton";
import { useState } from "react";

export default function SideBarButtonList() {
  const [selected, setSelected] = useState<number>(-1);

  const buttonList = [
    {
      icon: <HomeIconUnselected />,
      iconSelected: <HomeIconSelected />,

      text: "Accueil",
      link: "/dashboard",
    },
    {
      icon: <MenuIconUnselected />,
      iconSelected: <MenuIconSelected />,
      text: "Gestion du menu",
      link: "/dashboard/menus",
    },
    {
      icon: <FeedbackIconUnselected />,
      iconSelected: <FeedbackIconSelected />,
      text: "Avis clients",
      link: "/dashboard/reviews",
    },
  ];

  return (
    <Grid container direction="column" mt={9}>
      {buttonList.map((button, index) => (
        <div key={index}>
          <SideBarButton
            icon={button.icon}
            iconSelected={button.iconSelected}
            text={button.text}
            link={button.link}
            selected={selected === index}
            onClick={() => setSelected(index)}
          />
        </div>
      ))}
    </Grid>
  );
}
