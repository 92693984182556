import { Grid } from "@mui/material";

import { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useStatsBoxRepository from "../../../Repositories/HomePage/useStatsBoxRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import StatsBox from "../../../../../../common/components/StatsBox/StatsBox";
import { TBalanceBoxContainerProps } from "./types/TBalanceBoxContainerProps";
import { useAppSelector } from "../../../../../../common/store/redux";
import useFundsManagementRepository from "../../../../../Account/modules/FundsManagement/repository/useFundsManagementRepository";

export default function BalanceBoxContainer(props: TBalanceBoxContainerProps) {
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.IDLE
  );
  const { onFindAccountBalance } = useFundsManagementRepository();

  const availableBalance = useAppSelector(
    (state) => state.fundsManagement.availableBalance
  );

  useEffect(() => {
    if (props.stripeAccount && !availableBalance) {
      fetchStat(onFindAccountBalance, setLoadingState);
    }
  }, [props.stripeAccount, availableBalance]);
  return (
    <Grid container direction={"row"} sx={{ gap: "12px" }}>
      <StatsBox
        title="Solde du compte"
        stat={availableBalance ?? 0}
        loadingState={loadingState}
        currency={true}
      />
    </Grid>
  );
}
