import React from "react";
import { TReviewDetailsBoxProps } from "./types/TReviewDetailsBoxProps";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as EmptyStar } from "../../../../../../../common/images/review_star_empty1.svg";
import { ReactComponent as FullStar } from "../../../../../../../common/images/review_star_full1.svg";

export default function ReviewDetailsBox(props: TReviewDetailsBoxProps) {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < props.review.rating) {
        stars.push(<FullStar key={i} />);
      } else {
        stars.push(<EmptyStar key={i} />);
      }
    }
    return stars;
  };
  return (
    <Grid
      container
      sx={{ width: "100%", marginBottom: "25px" }}
      direction={"column"}
    >
      <Grid container justifyContent={"space-between"}>
        <Typography
          sx={{ color: "black", fontSize: "10px" }}
          className="font-poppins"
        >
          Chambre :{" "}
          <span style={{ fontWeight: "bold" }}>{props.review.tableNumber}</span>
        </Typography>
        <Grid item>{renderStars()}</Grid>
      </Grid>
      <Grid item>
        <Typography
          sx={{ color: "#777777", fontSize: "10px" }}
          className="font-poppins"
        >
          {props.review.message}
        </Typography>
      </Grid>
    </Grid>
  );
}
