import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { TStyledTextFieldProps } from "./types/TStyledTextFieldProps";

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    !["width", "height", "isBold"].includes(prop as string),
})<TStyledTextFieldProps>(({ width, height, multiline }) => ({
  width: width ?? "100%",
  height: height ?? "100%",
  "& .MuiOutlinedInput-root": {
    height: multiline ? "auto" : "100%",
    "& fieldset": {
      borderColor: "#E8EAED",
      borderWidth: "2px",
      borderRadius: "8px",
    },
    "& .MuiInputBase-input": {
      color: "black",
      fontWeight: "normal",
      height: multiline ? "auto" : "100%",
      fontSize: "12px",
      padding: "20px",
      "&::placeholder": {
        color: "gray", // Set your placeholder color here
        opacity: 1, // Ensure the placeholder is fully opaque
      },
    },
  },
}));
