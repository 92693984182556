import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useMenuDataRepository from "../../../Repositories/useMenuDataRepository/useMenuDataRepository";
import DateAndNamePageTitleContainer from "../../DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";
import CreateMenuItemContainer from "../CreateMenuItemContainer/CreateMenuItemContainer";
import DisplayItemListContainer from "../DisplayItemListContainer/DisplayItemListContainer";
import EditableNavigationBarContainer from "../EditableNavigationBarContainer/EditableNavigationBarContainer";

export default function MenuEditorContainer() {
  const { menuId, categoryId, itemId } = useParams();
  const { selectedMenu, menuLoading } = useMenuDataRepository(
    menuId,
    categoryId,
    itemId
  );

  return menuLoading !== LoadingStatesEnum.LOADING && selectedMenu ? (
    <Grid container direction={"column"}>
      <DateAndNamePageTitleContainer />
      <Grid
        container
        direction={"row"}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid item maxWidth={"70%"} width={"fit-content"} >
          <EditableNavigationBarContainer
            menu={selectedMenu}
            defaultSelectedCategoryId={
              categoryId ? parseInt(categoryId) : undefined
            }
          />
        </Grid>
        <Grid
          item
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CreateMenuItemContainer menu={selectedMenu} />
        </Grid>
      </Grid>

      {selectedMenu.categories.length > 0 && (
        <>
          <DisplayItemListContainer menu={selectedMenu} />
        </>
      )}
    </Grid>
  ) : (
    <></>
  );
}
