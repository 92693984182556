import { Box, Typography } from "@mui/material";
import React from "react";
import { TAutoSaveConfirmationBoxProps } from "./types/TAutoSaveConfirmationBoxProps";

export default function AutoSaveConfirmationBox(
  props: TAutoSaveConfirmationBoxProps
) {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: "8px",
        zIndex: 999,
        padding: "10px",

        alignItems: "center",
        display: "flex",
        gap: 1,
      }}
    >
      <Typography>Veuillez confirmer l'enregistrement : </Typography>
      <Typography
        sx={{
          color: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          "&:hover": { color: "black" },
        }}
        onClick={props.onConfirm}
      >
        Confirmer
      </Typography>
      <Typography
        sx={{
          color: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          "&:hover": { color: "black" },
        }}
        onClick={props.onUpdate}
      >
        Modifier
      </Typography>
      <Typography
        sx={{
          color: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          "&:hover": { color: "black" },
        }}
        onClick={props.onReset}
      >
        Réinitialiser
      </Typography>
    </Box>
  );
}
