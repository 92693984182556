import React from "react";
import { TDeleteMenuCategoryConfirmationModalProps } from "./types/TDeleteMenuCategoryConfirmationModalProps";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal/ConfirmationModal";

export default function DeleteMenuCategoryConfirmationModal(
  props: TDeleteMenuCategoryConfirmationModalProps
) {
  return (<ConfirmationModal
    title={`Etes-vous sûr de vouloir supprimer : ${props.category.name} ?`}
    isOpen={props.isOpen}
    handleClose={props.handleClose}
    handleAgree={props.handleDelete}
  />);
}
