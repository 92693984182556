import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import useStripeAccountRepository from "../../../../../../common/new/repositories/useStripeAccountRepository";
import { useAppSelector } from "../../../../../../common/store/redux";
import { TStripeContainerProps } from "./types/TStripeContainerProps";
import { StripeAccountVerificationStatusEnum } from "../../../../../../common/enums/StripeAccountVerificationStatusEnum";

export default function StripeContainer(props: TStripeContainerProps) {
  /** Loading state */
  const stripeAccount = useAppSelector(
    (state) => state.stripeAccount.stripeAccount
  );
  const { onCreateStripeAccount, onFindStripeAccount, onGetUpdateAccountLink } =
    useStripeAccountRepository();

  const handleOnClick = async () => {
    props.stripeAccount
      ? await onGetUpdateAccountLink()
      : await onCreateStripeAccount({ country: "FR" });
  };
  const handleFindAccount = async () => {
    await onFindStripeAccount();
  };

  useEffect(() => {
    !stripeAccount && handleFindAccount();
  }, []);

  return (
    <Box
      sx={{
        width: "246px",
        height: "139px",
        backgroundColor: "white",
        borderRadius: "15px",
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.1)",
        },
        cursor: "pointer",
      }}
      onClick={handleOnClick}
    >
      <Grid container direction={"column"}>
        <>
          <Typography
            sx={{
              color: "#FBBC04",
              fontSize: "15px",
              fontWeight: "500",
              textAlign: "center",
            }}
            className="font-roboto"
          >
            {!props.stripeAccount
              ? "Créer votre compte connecté Stripe pour débloquer vos statistiques"
              : "Complétez votre profil pour activer toutes les fonctionnalitées"}
          </Typography>
        </>
      </Grid>
    </Box>
  );
}
