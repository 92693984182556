import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import DashboardHeader from "../../../../common/components/DashboardHeader/DashboardHeader";
import ScrollableContent from "../../../../common/components/ScrollableContent/ScrollableContent";
import { useAppSelector } from "../../../../common/store/redux";
import BankTransferPage from "../../../Account/modules/FundsManagement/containers/Pages/BankTransferPage";
import IAMMainPage from "../../../IAM/containers/Pages/IAMMainPage";
import MainKitchenPage from "../../../Kitchen/containers/Pages/MainKitchenPage";
import HomePageContainer from "../../../new/Dashboard/Containers/HomePage/HomePageContainer/HomePageContainer";
import MenuManagementContainer from "../../../new/Dashboard/Containers/MenuRouterContainer/MenuRouterContainer";
import MainReviewsPage from "../../../Reviews/containers/Pages/MainReviewsPage";
import WaiterMainPage from "../../../Waiter/containers/Pages/WaiterMainPage";
import DashboardSideBar from "../SideBar/DashboardSideBar";
import RedirectToMenuPreviewPage from "./RedirectToMenuPreviewPage";

export default function MainDashboardPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const SIDEBAR_WIDTH = 104;
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const [hideSideBar, setHideSidebar] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isChef, setIsChef] = useState<boolean>(false);
  const [isWaiter, setIsWaiter] = useState<boolean>(false);

  useEffect(() => {
    if (authenticatedUser && authenticatedUser.role.name === "CHEF") {
      setIsChef(true);
    } else {
      setIsChef(false);
    }

    if (authenticatedUser && authenticatedUser.role.name === "WAITER") {
      setIsWaiter(true);
    } else {
      setIsWaiter(false);
    }
  }, [authenticatedUser]);

  return (
    <Box
      sx={{
        display: !isChef ? "flex" : "unset",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#F9F9F9",
      }}
    >
      {!isChef && !isMobile && <DashboardSideBar />}
      <ScrollableContent paddingLeft={37} paddingRight={37}>
        <Box>
          <DashboardHeader />
        </Box>
        {!isChef ? (
          <Routes>
            <Route path="menus/*" element={<MenuManagementContainer />} />
            <Route
              path="/"
              element={isWaiter ? <WaiterMainPage /> : <HomePageContainer />}
            />
            <Route path="/bank/transfer" element={<BankTransferPage />} />
            <Route path="/reviews" element={<MainReviewsPage />} />
            <Route path="/iam" element={<IAMMainPage />} />
            <Route
              path="/redirect-to-menupreview"
              element={<RedirectToMenuPreviewPage />}
            />
          </Routes>
        ) : (
          <Box sx={{ m: 4 }}>
            <Routes>
              <Route path="/" element={<MainKitchenPage />} />
            </Routes>
          </Box>
        )}
      </ScrollableContent>
    </Box>
  );
}
