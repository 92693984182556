import { Button, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useState } from "react";
import { TUpdateMenuModalProps } from "./types/TUpdateMenuModalProps";
import UpdateDefaultMenu from "./UpdateDefaultMenu";
import UpdateMenuTimeframeContent from "./UpdateMenuTimeframeContent";
import UpdateMenuTitleContent from "./UpdateMenuTitleContent";

export default function UpdateMenuModal(props: TUpdateMenuModalProps) {
  const [showUpdateTimeframeContent, setShowUpdateTimeframeContent] =
    useState<boolean>(false);
  const [showUpdateTitleContent, setShowUpdateTitleContent] =
    useState<boolean>(false);

  const handleCloseModal = () => {
    setShowUpdateTimeframeContent(false);
    setShowUpdateTitleContent(false);
    props.onClose();
  };

  const renderMenuContent = () => {
    switch (props.selectedMenu.menuType.type) {
      case "DEFAULT":
        return (
          <UpdateDefaultMenu
            selectedMenu={props.selectedMenu}
            onClose={props.onClose}
          />
        );
      case "MORNING":
        return showUpdateTimeframeContent ? (
          <UpdateMenuTimeframeContent
            selectedMenu={props.selectedMenu}
            onClose={props.onClose}
          />
        ) : (
          <Grid item xs={12} m={2}>
            <Button
              className="popup-button-blue"
              onClick={() => setShowUpdateTimeframeContent(true)}
            >
              Modifier la plage horaire
            </Button>
          </Grid>
        );
      default:
        return showUpdateTimeframeContent ? (
          <UpdateMenuTimeframeContent
            selectedMenu={props.selectedMenu}
            onClose={props.onClose}
          />
        ) : showUpdateTitleContent ? (
          <>
            <UpdateMenuTitleContent
              selectedMenu={props.selectedMenu}
              onClose={handleCloseModal}
            />
          </>
        ) : (
          <Grid item xs={12} m={2}>
            <Grid item mb={2}>
              <Button
                className="popup-button-blue"
                onClick={() => setShowUpdateTimeframeContent(true)}
              >
                Modifier la plage horaire
              </Button>
            </Grid>
            <Grid>
              <Button
                className="popup-button-blue"
                onClick={() => setShowUpdateTitleContent(true)}
              >
                Modifier le titre
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <MuiDialog open={props.isOpen} onClose={handleCloseModal} maxWidth="sm">
      <DialogTitle>Modification du menu</DialogTitle>
      <Grid container className="grid-center" p={2}>
        {renderMenuContent()}
      </Grid>
    </MuiDialog>
  );
}
