import { Box, Grid } from "@mui/material";
import React from "react";
import Logo from "../../images/logo.png";

export default function LogoCard() {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        height: "70px",
      }}
    >
      <Grid item sx={{ margin: "15px" }}>
        <img src={Logo} width={"100%"} height={"100%"} />
      </Grid>
    </Box>
  );
}
