import { useState } from "react";
import { FileStateEnum } from "../../../../../common/enums/FileStateEnum";
import {
  FileUploadErrorEnum,
  MAX_FILE_SIZE_MENU_ITEM_IMAGE,
  TFileUploadError,
} from "../../../../../common/enums/FileUploadErrorEnum";
import { TuseImageUploadProps } from "./types/TuseImageUploadProps";

export default function useImageUpload(props: TuseImageUploadProps) {
  const [image, setImage] = useState<string | null>(props.defaultImage);
  const [file, setFile] = useState<File | null>(null);
  const [fileState, setFileState] = useState<FileStateEnum>(FileStateEnum.NONE);
  const [fileUploadError, setFileUploadError] =
    useState<TFileUploadError | null>(null);

  const MAX_FILE_SIZE = MAX_FILE_SIZE_MENU_ITEM_IMAGE * 1024 * 1024; // 2MB in bytes

  const ErrorMessages = {
    [FileUploadErrorEnum.FILE_TOO_LARGE]: `Le fichier ne doit pas dépasser ${MAX_FILE_SIZE_MENU_ITEM_IMAGE}MB`,
  };

  const handleSetFileUploadError = (error: TFileUploadError | null) => {
    setFileUploadError(error);
    setFileState(FileStateEnum.ERROR);
  };

  const handleUploadFile = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*"; // Restrict to images only
    fileInput.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          // Handle file size error
          handleSetFileUploadError({
            code: FileUploadErrorEnum.FILE_TOO_LARGE,
            message: ErrorMessages[FileUploadErrorEnum.FILE_TOO_LARGE],
          });
          setFileState(FileStateEnum.ERROR);
          return;
        }

        handleSetFileUploadError(null); // Clear any previous error
        setFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setImage(reader.result as string); // Update the state with the image data URL
            setFileState(FileStateEnum.TO_UPLOAD);
          }
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const handleDeleteImage = () => {
    setFile(null);
    setImage(null);
    handleSetFileUploadError(null);
    setFileState(FileStateEnum.TO_DELETE);
  };

  return {
    file,
    image,
    handleUploadFile,
    fileState,
    handleDeleteImage,
    fileUploadError,
  };
}
