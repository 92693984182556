import React, { useEffect, useState } from "react";
import { TAddAllergenButtonProps } from "./types/TAddAllergenButtonProps";
import ItemAllergenEditorButton from "../../../Components/ItemAllergenEditorButton/ItemAllergenEditorButton";
import { ReactComponent as AddIcon } from "../../../../../../common/images/add_icon_small.svg";
import AddAllergensModal from "../../../Modals/AddAllergensModal/AddAllergensModal";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import { CircularProgress } from "@mui/material";

export default function AddAllergenButton(props: TAddAllergenButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (props.loadingState === LoadingStatesEnum.SUCCESS) {
      handleCloseModal();
    }
  }, [props.loadingState]);

  return (
    <>
      <AddAllergensModal
        menuItem={props.menuItem}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleUpdateMenuItemAllergen={props.handleUpdateMenuItemAllergen}
        loadingState={props.loadingState}
        allAllergensList={props.allAllergensList}
      />
      <ItemAllergenEditorButton
        content={
          props.loadingState === LoadingStatesEnum.LOADING ? (
            <CircularProgress />
          ) : (
            <AddIcon />
          )
        }
        onClick={handleOpenModal}
        colorMode={"BLUE"}
        disabled={
          props.loadingState === LoadingStatesEnum.LOADING ? true : false
        }
      />
    </>
  );
}
