import { Box, Typography } from "@mui/material";
import AddButtonOrange from "../AddButtonOrange/AddButtonOrange";
import { TFooterSectionProps } from "./types/TFooterSectionProps";
import MoreButtonMenuItem from "../../Containers/MenuEditorPage/MoreButtonMenuItem/MoreButtonMenuItemContainer";

export default function FooterSection(props: TFooterSectionProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* Replace with actual footer content */}
      <Typography
        sx={{ fontSize: "22px", color: "#222222", fontWeight: "bold" }}
      >
        {`€${props.menuItem.price}`}
      </Typography>
      <MoreButtonMenuItem menuItem={props.menuItem} />
    </Box>
  );
}
