import { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useReviewsOverviewRepository from "../../../Repositories/HomePage/useReviewsOverviewRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import ReviewsOverviewCard from "../../../Components/RightSidePanel/Review/ReviewsOverviewCard/ReviewsOverviewCard";

export default function ReviewsOverviewContainer() {
  const [lastFiveReviewsLoadingState, setLastFiveReviewsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.IDLE);

  const { onFindLastFiveReviews, lastFiveReview } =
    useReviewsOverviewRepository();

  useEffect(() => {
    if (lastFiveReview === null) {
      fetchStat(onFindLastFiveReviews, setLastFiveReviewsLoadingState);
    }
  }, []);
  return (
    <ReviewsOverviewCard
      reviews={lastFiveReview ?? []}
      loadingState={lastFiveReviewsLoadingState}
    />
  );
}
