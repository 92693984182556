import { useState } from "react";
import { useAppSelector } from "../../../../../common/store/redux";
import { TCustomBarChartAttributes } from "../../Components/BarChart/CustomBarChart/types/TCustomBarChartAttributes";

export default function useCustomBarChartRepository() {
  const orders = useAppSelector((state) => state.cartSlice.carts);
  const [currentWeekOrders, setCurrentWeekOrders] = useState<
    TCustomBarChartAttributes[] | null
  >(null);

  function initializeWeeklyBarChartData(): TCustomBarChartAttributes[] {
    const today = new Date();
    const weekData: TCustomBarChartAttributes[] = [];

    // Calculate Monday of the current week
    const monday = new Date(today);
    monday.setDate(today.getDate() - ((today.getDay() + 6) % 7));

    // Create dates from Monday to Sunday, each with a value of 0
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);

      weekData.push({
        date: date,
        value: 0,
      });
    }

    return weekData;
  }

  const onFindCurrentWeekOrdersNbr = async (): Promise<boolean> => {
    if (!orders || orders.length === 0) {
      return false;
    }

    const weekOrders: TCustomBarChartAttributes[] =
      initializeWeeklyBarChartData();

    const orderCountByDay = weekOrders.map(
      (weekOrder) =>
        orders.filter(
          (order) =>
            order.date &&
            order.date.getDay() === weekOrder.date.getDay() &&
            order.date.getFullYear() === weekOrder.date.getFullYear() &&
            order.date.getMonth() === weekOrder.date.getMonth()
        ).length
    );

    const updatedWeekOrders = weekOrders.map((weekOrder, index) => ({
      ...weekOrder,
      value: orderCountByDay[index],
    }));

    setCurrentWeekOrders(updatedWeekOrders);
    return true;
  };

  return {
    onFindCurrentWeekOrdersNbr,
    currentWeekOrders,
  };
}
