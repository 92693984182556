import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { StyledTextField } from "../../../../../../common/components/StyledTextField/StyledTextField";
import useAutoSaveField from "../../../Hooks/useAutoSaveField/useAutoSaveField";
import AutoSaveConfirmationBox from "../AutoSaveConfirmationBox/AutoSaveConfirmationBox";
import { TAutoSaveNumberFieldProps } from "./types/TAutoSaveNumberFieldProps";
import CustomNumberField from "../../../../../../common/components/CustomNumberField/CustomNumberField";

export default function AutoSaveNumberField(props: TAutoSaveNumberFieldProps) {
  const [inputValue, setInputValue] = useState(props.defaultValue || "");
  const {
    handleKeepModifying,
    handleResetField,
    showEndAdornment,
    handlePressKeyDown,
    handleSaveValue,
    showSuccessAnimation,
    showConfirmationBox,
    setShowConfirmationBox,
  } = useAutoSaveField({
    defaultValue: props.defaultValue,
    inputValue: inputValue,
    setInputValue: setInputValue,
    onSaveValue: props.onSaveValue,
    loadingState: props.loadingState,
  });

  const shouldSaveValue = (value: string): boolean => {
    return value !== props.defaultValue && value !== "";
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (shouldSaveValue(event.target.value)) {
      setShowConfirmationBox(true);
    }
  };

  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography
          className="font-sen"
          sx={{ fontSize: "13px", color: "black", textTransform: "uppercase" }}
          mb={1}
        >
          {props.title}
        </Typography>
      </Grid>

      <Grid item sx={{ position: "relative" }}>
        {showConfirmationBox && (
          <AutoSaveConfirmationBox
            onUpdate={handleKeepModifying}
            onReset={handleResetField}
            onConfirm={handleSaveValue}
          />
        )}
        <CustomNumberField
          handleOnBlur={handleOnBlur}
          inputValue={inputValue}
          setInputValue={setInputValue}
          showEndAdornment={showEndAdornment}
          width={props.width}
          height={props.height}
          placeholder={props.placeholder}
          handlePressKeyDown={handlePressKeyDown}
          decimalPlaces={props.decimalPlaces}
          allowDecimals={props.allowDecimals}
        />
      </Grid>
    </Grid>
  );
}
