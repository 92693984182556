import { Box } from "@mui/material";
import DailyOrderCardContainer from "../../../Containers/HomePage/DailyOrderCardContainer/DailyOrderCardContainer";
import ReviewsOverviewContainer from "../../../Containers/HomePage/ReviewsOverviewContainer/ReviewsOverviewContainer";

export default function StatsOverviewPanel() {
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "white",
        height: "650px",
        width: "370px",
        right: 0,
        top: 0,
        zIndex: 0,

        borderLeft: "2px solid",
        borderBottom: "2px solid",
        borderColor: "#E4E4E4",
        borderBottomLeftRadius: "5px",
      }}
    >
      <Box sx={{ padding: "15px" }}>
        <DailyOrderCardContainer />
        <ReviewsOverviewContainer />
      </Box>
    </Box>
  );
}
