import React from "react";
import { TUpdateMenuTimeframeContentProps } from "./types/TUpdateMenuTimeframeContentProps";
import UpdateMenuTimeframeForm from "../Forms/UpdateMenuTimeframeForm";
import { selectMenuList } from "../../selectors/menuSelector";
import { useAppSelector } from "../../../../../../common/store/redux";

export default function UpdateMenuTimeframeContent(
  props: TUpdateMenuTimeframeContentProps
) {
  const menuList = useAppSelector(selectMenuList);
  return (
    <div>
      <UpdateMenuTimeframeForm
        menuList={menuList}
        selectedMenu={props.selectedMenu}
        onClose={props.onClose}
      />
    </div>
  );
}
