import React from 'react'
import { getAllMenuAllergen } from '../../../modules/Menu/modules/allergens/services/menuAllergenService';
import { menuAllergenActions } from '../../../modules/Menu/modules/allergens/slices/menuAllergen';
import { IMenuAllergenEntity } from '../../Entities/IMenuAllergenEntity';
import { useAppDispatch } from '../../store/redux';
import { deleteMenuItemAllergen, updateMenuItemAllergenFromList } from '../../../modules/Menu/modules/allergens/services/menuItemAllergenService';
import { TOnUpdateMenuItemAllergenFromListRequest } from '../../../modules/Menu/modules/allergens/services/type/Requests/TUpdateMenuItemAllergenFromListRequest';
import { menuActions } from '../../../modules/Menu/modules/menu/slice/menuSlice';
import { IMenuItemAllergenEntity } from '../../Entities/IMenuItemAllergenEntity';
import { menuItemAllergenActions } from '../../../modules/Menu/modules/allergens/slices/menuItemAllergen';
import { TDeleteMenuItemAllegenRequest } from '../../../modules/Menu/modules/allergens/services/type/Requests/TDeleteMenuItemAllegenRequest';

export default function useMenuItemAllergenRepository() {
    const dispatch = useAppDispatch();

  async function onGetAllMenuAllergen(): Promise<boolean> {
    const resultAction = await dispatch(getAllMenuAllergen());

    if (getAllMenuAllergen.rejected.match(resultAction)) {
      return false;
    }
    const menuAllergenList = resultAction.payload.menuAllergens;
    dispatch(menuItemAllergenActions.setAllAllergensList(menuAllergenList));
    return true;
  }

  async function onUpdateMenuItemAllergenFromList(
    requestData: TOnUpdateMenuItemAllergenFromListRequest
  ): Promise<boolean> {
    const resultAction = await dispatch(
      updateMenuItemAllergenFromList(requestData)
    );

    if (updateMenuItemAllergenFromList.rejected.match(resultAction)) {
      return false;
    }
    const updatedMenuItemAllergenList = resultAction.payload.newList;
    console.log("toto",updatedMenuItemAllergenList)
    dispatch(
      menuActions.updateMenuItemAllergenList({
        menuItemAllergenList:updatedMenuItemAllergenList}
    ));
    return true;;
  }
  async function onDeleteAllergen(
    requestData: TDeleteMenuItemAllegenRequest
  ): Promise<boolean> {
    const resultAction = await dispatch(
      deleteMenuItemAllergen(requestData)
    );

    if (deleteMenuItemAllergen.rejected.match(resultAction)) {
      return false;
    }
    dispatch(
      menuActions.removeFromMenuItemAllergenList({
        menuItemAllergenId: requestData.menuItemAllergenId,
      })
    );
    return true;;
  }
  
  return {
    onGetAllMenuAllergen,
    onUpdateMenuItemAllergenFromList,onDeleteAllergen
  };
}
