import { Box, DialogTitle } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { LoadingStatesEnum } from "../../../../../common/enums/LoadingStatesEnum";
import AllergenDisplayButton from "../../Containers/MenuItemEditorPage/AllergenDisplayButton/AllergenDisplayButton";
import SaveButton from "./SaveButton/SaveButton";
import { TAddAllergensModalProps } from "./types/TAddAllergensModalProps";

export default function AddAllergensModal(props: TAddAllergensModalProps) {
  return (
    <MuiDialog
      open={props.isOpen}
      onClose={() => {
        props.loadingState !== LoadingStatesEnum.LOADING && props.handleClose();
      }}
      PaperProps={{
        sx: {
          width: "600px", // Specified modal width
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <DialogTitle
        sx={{ fontSize: "25px", color: "black", textAlign: "center" }}
        className="font-sen"
      >
        Allergènes
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Centers the group of buttons as a block
          justifyContent: "center",
          width: "100%",
          gap: 2,
        }}
      >
        {props.allAllergensList ? (
          <>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // Set maximum 3 items per row
                gap: 1, // Adds spacing between items
                justifyContent: "center", // Centers the grid within the modal
              }}
            >
              {props.allAllergensList.map((a, index) => (
                <AllergenDisplayButton
                  key={index}
                  menuItemAllergen={{
                    menuItemId: props.menuItem.id!,
                    menuAllergen: a,
                  }}
                  enableBig={true}
                />
              ))}
            </Box>
            <SaveButton
              loadingState={props.loadingState}
              handleClick={props.handleUpdateMenuItemAllergen}
            />
          </>
        ) : null}
      </Box>
    </MuiDialog>
  );
}
