import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../common/store/redux";

export default function useDailyOrderCardRepository() {
  const orders = useAppSelector((state) => state.cartSlice.carts);
  const [todayOrdersNbr, setTodayOrdersNbr] = useState<number | null>(null);

  const onFindTodayOrdersNbr = async (): Promise<boolean> => {
    if (orders && orders.length > 0) {
      const todayDate = new Date();

      // Filter and calculate
      const todayOrders = orders.filter(
        (order) => order.date?.getDay() === todayDate.getDay()
      );

      setTodayOrdersNbr(todayOrders.length);
      return true;
    }
    return false;
  };
  return { onFindTodayOrdersNbr, todayOrdersNbr };
}
