import { Grid } from "@mui/material";

import { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useStatsBoxRepository from "../../../Repositories/HomePage/useStatsBoxRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import StatsBox from "../../../../../../common/components/StatsBox/StatsBox";

export default function TotalOrdersBoxContainer() {
  const [
    totalCurrentMonthOrderNbrLoadingState,
    setTotalCurrentMonthOrderNbrLoadingState,
  ] = useState<LoadingStatesEnum>(LoadingStatesEnum.IDLE);

  const { onFindCurrentMonthOrderNbr, currentMonthOrdersNbr } =
    useStatsBoxRepository();

  useEffect(() => {
    if (currentMonthOrdersNbr === null) {
      fetchStat(
        onFindCurrentMonthOrderNbr,
        setTotalCurrentMonthOrderNbrLoadingState
      );
    }
  }, []);
  return (
    <Grid container direction={"row"} sx={{ gap: "12px" }}>
      <StatsBox
        title="Total des commandes"
        stat={currentMonthOrdersNbr ?? 0}
        loadingState={totalCurrentMonthOrderNbrLoadingState}
      />
    </Grid>
  );
}
