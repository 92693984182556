import { Route, Routes } from "react-router-dom";

import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { selectMenuList } from "../../../../Menu/modules/menu/selectors/menuSelector";
import { findAllMenus } from "../../../../Menu/modules/menu/service/menusServices";
import MenuDashboardContainer from "../MenuDashboardPage/MenuDashboardContainer/MenuDashboardContainer";
import MenuEditorContainer from "../MenuEditorPage/MenuEditorContainer/MenuEditorContainer";
import MenuItemEditorContainer from "../MenuItemEditorPage/MenuItemEditorContainer/MenuItemEditorContainer";

export default function MenuRouterContainer() {
  /* Refactor later */
  const menuList = useAppSelector(selectMenuList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    !menuList || (menuList.length === 0 && dispatch(findAllMenus()));
  }, [JSON.stringify(menuList)]);
  /* ------------------------------*/
  return (
    <Routes>
      <Route
        path="/"
        element={<MenuDashboardContainer menuList={menuList} />}
      />
      <Route path="/:menuId/:categoryId?" element={<MenuEditorContainer />} />
      <Route
        path="/:menuId/category/:categoryId/item/:itemId"
        element={<MenuItemEditorContainer />}
      />
    </Routes>
  );
}
