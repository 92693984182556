import { Box } from "@mui/material";
import { TImagePreviewBoxProps } from "./types/TImagePreviewBoxProps";

export default function ImagePreviewBox(props: TImagePreviewBoxProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <img
        src={props.image}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "17px",
        }}
      />
    </Box>
  );
}
