import React, { useEffect } from "react";
import { TRHFNumberFieldProps } from "./types/TRHFNumberFieldProps";
import { useFormContext, Controller } from "react-hook-form";
import { StyledTextField } from "../StyledTextField/StyledTextField";

export default function RHFNumberField(props: TRHFNumberFieldProps) {
    const {
        control,
        formState: { errors },
        setValue,
      } = useFormContext();
    
      useEffect(() => {
        console.log("changed", props.defaultValue);
        setValue(props.name, props.defaultValue || "");
      }, [props.defaultValue, props.name, setValue]);

      const setInputValue = (value:string) => {
        setValue(props.name,value);
      }

      const handleOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const formattedValue = formatNumber(event.target.value);
        setInputValue(formattedValue);
        
    }

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const inputVal = event.target.value;
    
        // Only allow numeric input when not allowing decimals
        if (!props.allowDecimals) {
          const numericValue = inputVal.replace(/[^0-9]/g, "");
          setInputValue(numericValue);
          return;
        }
    
        // Allow numeric input and single dot or comma
        const numericValue = inputVal.replace(/[^0-9.,]/g, "");
       setInputValue(numericValue);
      };

    const formatNumber = (value: string): string => {
        // Remove any non-numeric characters except dot and comma
        const cleanedValue = value.replace(/[^0-9.,]/g, "");
    
        // Replace comma with dot if needed
        const normalizedValue = cleanedValue.replace(",", ".");
    
        // If decimals are not allowed, return only integer part
        if (!props.allowDecimals) {
          return normalizedValue.split(".")[0];
        }
    
        // Split into integer and decimal parts
        const [integerPart, decimalPart] = normalizedValue.split(".");
    
        // Trim decimal part to specified places or remove entirely if not needed
        if (decimalPart) {
          const trimmedDecimal = decimalPart.slice(0, props.decimalPlaces);
    
          // Remove trailing zeros
          const cleanedDecimal = trimmedDecimal.replace(/0+$/, "");
    
          // Combine parts
          return cleanedDecimal ? `${integerPart}.${cleanedDecimal}` : integerPart;
        }
    
        return integerPart;
      };
  return     <Controller
  name={props.name}
  control={control}
  rules={props.validation}
  defaultValue={props.defaultValue}
  render={({ field }) => (
    <StyledTextField
      {...field}
      value={field.value || ""}
      onChange={(e) =>{ field.onChange(e.target.value); handleChange(e)}}
      onBlur={(e)=>{field.onBlur(); handleOnBlur(e); }}
      variant="outlined"
      type="text"
      width={props.width}
      height={props.height}
      InputLabelProps={{
        shrink: true,
      }}
      placeholder={props.placeholder}
    />
  )}
/>;
}
