import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../common/store/redux";

export default function useStatsBoxRepository() {
  const orders = useAppSelector((state) => state.cartSlice.carts);
  const [currentMonthOrdersNbr, setCurrentMonthOrdersNbr] = useState<
    number | null
  >(null);
  const [acceptedOrdersPercentage, setAcceptedOrdersPercentage] = useState<
    number | null
  >(null);

  const onFindCurrentMonthOrderNbr = async (): Promise<boolean> => {
    //If there already orders
    if (orders && orders.length > 0) {
      const todayDate = new Date();
      const currentMonth = todayDate.getMonth();
      const currentYear = todayDate.getFullYear();

      // Filter and calculate
      const currentMonthOrders = orders.filter(
        (order) =>
          order.date?.getMonth() === currentMonth &&
          order.date?.getFullYear() === currentYear
      );
      const ordersNbr = currentMonthOrders.length;

      setCurrentMonthOrdersNbr(ordersNbr);
      return true;
    }
    return false;
  };
  const onFindAcceptedOrdersPercentage = async (): Promise<boolean> => {
    if (orders && orders.length > 0) {
      const todayDate = new Date();
      const currentMonth = todayDate.getMonth();
      const currentYear = todayDate.getFullYear();

      // Filter and calculate
      const currentMonthOrders = orders.filter(
        (order) =>
          order.date?.getMonth() === currentMonth &&
          order.date?.getFullYear() === currentYear
      );
      const ordersNbr = currentMonthOrders.length;
      const acceptedCount = currentMonthOrders.filter(
        (order) => order.status === "READY"
      ).length;
      const acceptedPercentage =
        ordersNbr > 0 ? (acceptedCount / ordersNbr) * 100 : 0;

      setAcceptedOrdersPercentage(acceptedPercentage);
      return true;
    }
    return false;
  };
  return {
    onFindCurrentMonthOrderNbr,
    onFindAcceptedOrdersPercentage,
    currentMonthOrdersNbr,
    acceptedOrdersPercentage,
  };
}
