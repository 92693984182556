import { Grid } from "@mui/material";
import React from "react";
import NoPPIcon from "../../images/header_nopp_icon.png";

export default function UserProfileSettingsIcon() {
  return (
    <Grid
      item
      sx={{
        width: "52px",
        height: "52px",
        cursor: "pointer",
        borderRadius: "8px",
        backgroundColor: "#008489",
        boxShadow: "0px 1px 1.5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <img
        src={NoPPIcon}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </Grid>
  );
}
