import { ReactElement, useEffect, useState } from "react";
import { IMenuItemAllergenEntity } from "../../../../../../common/Entities/IMenuItemAllergenEntity";
import { ReactComponent as CloseIcon } from "../../../../../../common/images/close_icon.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { menuItemAllergenActions } from "../../../../../Menu/modules/allergens/slices/menuItemAllergen";
import AddIconAllergen from "../../../Components/AddIconAllergen/AddIconAllergen";
import CloseIconAllergen from "../../../Components/CloseIconAllergen/CloseIconAllergen";
import ItemAllergenEditorButton from "../../../Components/ItemAllergenEditorButton/ItemAllergenEditorButton";
import { TColorMode } from "../../../Components/ItemAllergenEditorButton/types/TItemAllergenEditorButtonProps";
import { TAllergenDisplayButtonProps } from "./types/TAllergenDisplayButtonProps";
import { Box } from "@mui/material";

export default function AllergenDisplayButton(
  props: TAllergenDisplayButtonProps
) {
  const bigProps = {
    iconWidth: "29",
    iconHeight: "29",
    buttonWidth: "169px",
    buttonHeight: "64px",
  };
  const currentItemAllergenList = useAppSelector(
    (state) => state.menuItemAllergen.currentItemAllergenList
  );
  const [renderIcon, setRenderIcon] = useState<ReactElement<any, any>>(
    <CloseIcon />
  );
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [colorMode, setColorMode] = useState<TColorMode>("WHITE");
  const dispatch = useAppDispatch();
  const handleButtonMode = (isAdded: boolean) => {
    isAdded ? setColorMode("BLUE") : setColorMode("WHITE");
  };
  const handleIsAdded = (
    currentList: IMenuItemAllergenEntity[],
    itemAllergen: IMenuItemAllergenEntity
  ) => {
    const foundAllergen = currentList.find(
      (c) => c.menuAllergen.id === itemAllergen.menuAllergen.id
    );
    if (foundAllergen) {
      setIsAdded(true);
      return;
    }
    setIsAdded(false);
  };

  const handleRenderIcon = (isAdded: boolean) => {
    return isAdded
      ? setRenderIcon(
          <CloseIconAllergen
            width={props.enableBig ? bigProps.iconWidth : undefined}
            height={props.enableBig ? bigProps.iconHeight : undefined}
          />
        )
      : setRenderIcon(
          <AddIconAllergen
            width={props.enableBig ? bigProps.iconWidth : undefined}
            height={props.enableBig ? bigProps.iconHeight : undefined}
          />
        );
  };
  const handleOnClick = () => {
    if (!isAdded) {
      console.log("aaaaaaaaaaaaaaaa", props.menuItemAllergen);
      dispatch(
        menuItemAllergenActions.addToCurrentItemAllergenList({
          menuItemAllergen: props.menuItemAllergen,
        })
      );
    } else {
      console.log("aaaaaaaaaaaaaaaa", props.menuItemAllergen);
      if(props.handleDeleteMenuItemAllergen){
        props.handleDeleteMenuItemAllergen(props.menuItemAllergen.id!);
      }
      else{
        dispatch(
          menuItemAllergenActions.removeFromCurrentItemAllergenList({
            itemAllergen: props.menuItemAllergen,
          })
        );
      }
       
    }
  };
  useEffect(() => {
    handleRenderIcon(isAdded);
    handleButtonMode(isAdded);
  }, [isAdded]);
  useEffect(() => {
    handleIsAdded(currentItemAllergenList, props.menuItemAllergen);
  }, [JSON.stringify(currentItemAllergenList)]);
  return (
    <ItemAllergenEditorButton
      content={props.menuItemAllergen.menuAllergen.name}
      onClick={handleOnClick}
      onClickTopCorner={handleOnClick}
      topCornerIcon={renderIcon}
      colorMode={colorMode}
      width={props.enableBig ? bigProps.buttonWidth : undefined}
      height={props.enableBig ? bigProps.buttonHeight : undefined}
    />
  );
}
