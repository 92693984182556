import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { DeleteOutlineRounded, EditOutlined } from "@mui/icons-material";
import { TImageEditorBoxProps } from "./types/TImageEditorBoxProps";
import ImagePreviewBox from "../ImagePreviewBox/ImagePreviewBox";

export default function ImageEditorBox(props: TImageEditorBoxProps) {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        backgroundColor: hovered ? "rgba(235,235,235,0.25)" : "",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ImagePreviewBox image={props.image} />

      {/* Icons displayed when hovered */}
      {hovered && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            gap: 1, // Adds space between the icons
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "#000",
            }}
            onClick={props.handleUploadFile}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "#000",
            }}
            onClick={props.handleDeleteImage}
          >
            <DeleteOutlineRounded />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
