import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import MenusNavigationBar from '../MenusNavigationBar/MenusNavigationBar'
import { TEditableNavigationBarProps } from './types/TEditableNavigationBarProps';

export default function EditableNavigationBar(props:TEditableNavigationBarProps) {
    const navigationBarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            navigationBarRef.current &&
            !navigationBarRef.current.contains(event.target as Node)
          ) {
            props.setIsEditing(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
  return (
    <><Box
          sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.15)",
              position: "absolute",
              zIndex: 10,
              left: 0,
              bottom: 0,
          }}
      ></Box><Box style={{ zIndex: 15 }} ref={navigationBarRef}>
              <MenusNavigationBar
                  nameList={props.editableTitleList}
                  selectedIndex={props.selectedIndex}
                  setSelectedIndex={props.setSelectedIndex} />
          </Box></>
  )
}
