import React from "react";
import { TStatsBoxProps } from "./types/TStatsBoxProps";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { LoadingStatesEnum } from "../../enums/LoadingStatesEnum";

export default function StatsBox(props: TStatsBoxProps) {
  function formatStatValue(value: number): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    }
    return value.toString();
  }

  const handleDisplayStatValue = (
    value: number,
    loadingState: LoadingStatesEnum
  ): string | JSX.Element => {
    const formattedValue =
      formatStatValue(value) + `${props.currency ? " €" : ""}`;
    switch (loadingState) {
      case LoadingStatesEnum.IDLE: {
        return `${formattedValue} `;
      }
      case LoadingStatesEnum.FAILURE: {
        return `0`;
      }
      case LoadingStatesEnum.LOADING: {
        return <CircularProgress />;
      }
      case LoadingStatesEnum.SUCCESS: {
        return `${formattedValue} `;
      }
    }
  };
  return (
    <Box
      sx={{
        width: "246px",
        height: "139px",
        backgroundColor: "white",
        borderRadius: "15px",
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container direction={"column"}>
        <Typography
          sx={{
            color: "#FBBC04",
            fontSize: "15px",
            fontWeight: "500",
            textAlign: "center",
          }}
          className="font-roboto"
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "36px",
            fontWeight: "500",
            textAlign: "center",
          }}
          className="font-barlow"
        >
          {handleDisplayStatValue(props.stat, props.loadingState)}
        </Typography>
      </Grid>
    </Box>
  );
}
