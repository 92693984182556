import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as AddIcon } from "../../../../../common/images/add_icon_orange.svg";
import { useNavigate } from "react-router-dom";
import { TAddButtonOrangeProps } from "./types/TAddButtonOrangeProps";

export default function AddButtonOrange(props: TAddButtonOrangeProps) {
  return (
    <Box onClick={props.onClick} sx={{ cursor: "pointer" }}>
      <AddIcon />
    </Box>
  );
}
