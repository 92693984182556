import { Grid, Typography } from "@mui/material";
import React from "react";
import { TUserNameAndRoleCardProps } from "./types/TUserNameAndRoleCardProps";

export default function UserNameAndRoleCard(props: TUserNameAndRoleCardProps) {
  return (
    <Grid item marginRight={"25px"}>
      <Grid container direction={"column"}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "600" }}
          className="font-barlow"
        >
          {props.firstName} {props.lastName}
        </Typography>
        <Grid item sx={{ justifyContent: "end", display: "flex" }}>
          <Typography
            sx={{ fontSize: "12px", color: "#777777" }}
            className="font-barlow"
          >
            {props.roleName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
