import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReviewsEntity } from "../../Entities/IReviewsEntity";
import { TReviewSliceState } from "./types/TReviewSliceState";

const initialState: TReviewSliceState = {
  reviews: null,
};

const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  reducers: {
    setReviews: (state, action: PayloadAction<IReviewsEntity[]>) => {
      state.reviews = action.payload;
    },
  },
});

export const reviewSliceReducer = reviewSlice.reducer;
export const reviewSLiceActions = reviewSlice.actions;
