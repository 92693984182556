import { Box, Typography } from "@mui/material";
import { TTextContentSectionProps } from "./types/TTextContentSectionProps";

export default function TextContentSection(props: TTextContentSectionProps) {
  return (
    <Box width={"100%"} height={"100%"}>
      {/* First Typography */}
      <Typography
        sx={{
          fontSize: "19px",
          color: "#222222",
          wordBreak: "break-word",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: "bold",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          marginBottom: "5px",
          "&::first-letter": {
            textTransform: "uppercase",
          },
        }}
        className="font-inter"
      >
        {props.name}
      </Typography>

      {/* Second Typography */}
      <Typography
        sx={{
          fontSize: "15px",
          color: "#5F5F5F",
          fontWeight: "300",
          display: "-webkit-box", //Replaced this from "inline-block" to allow multiline
          wordBreak: "break-all", //Allow the text to go all the way to the end of the line and no white sapce at the end
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        className="font-inter"
      >
        {props.description}
      </Typography>
    </Box>
  );
}
