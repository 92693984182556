import { Box, Grid } from "@mui/material";

import CustomBarChartContainer from "../CustomBarChartContainer/CustomBarChartContainer";

import { useEffect } from "react";
import { StripeAccountVerificationStatusEnum } from "../../../../../../common/enums/StripeAccountVerificationStatusEnum";
import { useAppSelector } from "../../../../../../common/store/redux";
import useCartRepository from "../../../../../Cart/repository/useCartRepository";
import useReviewsRepository from "../../../../../Reviews/repository/useReviewsRepository";
import StatsOverviewPanel from "../../../Components/RightSidePanel/StatsOverviewPanel/StatsOverviewPanel";
import DateAndNamePageTitleContainer from "../../DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";
import StripeContainer from "../StripeContainer/StripeContainer";
import TotalOrdersBoxContainer from "../TotalOrdersBoxContainer/TotalOrdersBoxContainer";
import BalanceBoxContainer from "../BalanceBoxContainer/BalanceBoxContainer";
import EarningBoxContainer from "../EarningBoxContainer/EarningBoxContainer";

export default function HomePageContainer() {
  const stripeAccount = useAppSelector(
    (state) => state.stripeAccount.stripeAccount
  );
  const orders = useAppSelector((state) => state.cartSlice.carts);
  const reviews = useAppSelector((state) => state.reviewSlice.reviews);

  const { onFindMonthOrders } = useCartRepository();
  const { onFindAllReviews } = useReviewsRepository();

  useEffect(() => {
    !orders && onFindMonthOrders();
    !reviews && onFindAllReviews();
  }, []);

  const blurClass =
    stripeAccount?.verificationStatus ===
    StripeAccountVerificationStatusEnum.VERIFIED
      ? ""
      : "blur";

  return !orders && !reviews ? (
    <Grid container direction={"column"}>
      <Box className={blurClass}>
        <StatsOverviewPanel />
      </Box>
      <DateAndNamePageTitleContainer />

      <Grid
        container
        direction={"row"}
        sx={{ gap: "12px", marginBottom: "25px" }}
      >
        <Grid item>
          {stripeAccount?.verificationStatus ===
          StripeAccountVerificationStatusEnum.VERIFIED ? (
            <><EarningBoxContainer /></>
          ) : (
            <>
              <StripeContainer stripeAccount={stripeAccount} />
            </>
          )}
        </Grid>
        <Grid item className={blurClass}>
          <TotalOrdersBoxContainer />
        </Grid>
        <Grid item className={blurClass}>
          <BalanceBoxContainer stripeAccount={stripeAccount} />
        </Grid>
      </Grid>
      <Grid item className={blurClass}>
        <CustomBarChartContainer />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
