import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyledTextField } from "../StyledTextField/StyledTextField";
import { TRHFTextFieldProps } from "./types/TRHFTextFieldProps";

export default function RHFTextField(props: TRHFTextFieldProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    console.log("changed", props.defaultValue);
    setValue(props.name, props.defaultValue || "");
  }, [props.defaultValue, props.name, setValue]);
  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validation}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <StyledTextField
          {...field}
          value={field.value || ""}
          onChange={(e) => field.onChange(e.target.value)}
          variant="outlined"
          type="text"
          width={props.width}
          height={props.height}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}
