import React from "react";
import { Box } from "@mui/material";

interface ScrollableContentProps {
  children: React.ReactNode;
  paddingLeft: number;
  paddingRight: number;
}

const ScrollableContent: React.FC<ScrollableContentProps> = ({
  children,
  paddingLeft,
  paddingRight,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        flex: 1,
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`,
      }}
    >
      {children}
    </Box>
  );
};

export default ScrollableContent;
