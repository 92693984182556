import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { TMenuItemAllergenSliceState } from "./types/TMenuItemAllergenSliceState";
import { IMenuItemAllergenEntity } from "../../../../../common/Entities/IMenuItemAllergenEntity";
import { IMenuAllergenEntity } from "../../../../../common/Entities/IMenuAllergenEntity";

const initialState: TMenuItemAllergenSliceState = {
  selectedMenuItemAllergen: null,
  menuItemAllergenList: [],
  hasMenuItemAllergenChanged: false,
  currentItemAllergenList: [],
  allAllergensList: null,
};

const menuItemAllergenSlice = createSlice({
  name: "menuItemAllergen",
  initialState,
  reducers: {
    setAllAllergensList: (
      state,
      action: PayloadAction<IMenuAllergenEntity[]>
    ) => {
      state.allAllergensList = action.payload;
    },
    setCurrentItemAllergenList: (
      state,
      action: PayloadAction<IMenuItemAllergenEntity[]>
    ) => {
      state.currentItemAllergenList = action.payload;
    },
    setMenuItemAllergenList: (
      state,
      action: PayloadAction<IMenuItemAllergenEntity[]>
    ) => {
      state.menuItemAllergenList = action.payload;
    },
    addToCurrentItemAllergenList: (
      state,
      action: PayloadAction<{
        menuItemAllergen: IMenuItemAllergenEntity;
      }>
    ) => {
      state.currentItemAllergenList.push({
        menuItemId: action.payload.menuItemAllergen.menuItemId,
        menuAllergen: action.payload.menuItemAllergen.menuAllergen,
      });
    },
    removeFromCurrentItemAllergenList: (
      state,
      action: PayloadAction<{
        itemAllergen: IMenuItemAllergenEntity;
      }>
    ) => {
      state.currentItemAllergenList = state.currentItemAllergenList.filter(
        (c) =>
          c.menuAllergen.id !== action.payload.itemAllergen.menuAllergen.id!
      );
    },
    addMenuAllergenToList: (
      state,
      action: PayloadAction<{
        menuItemId: number;
        allergen: IMenuAllergenEntity;
      }>
    ) => {
      state.menuItemAllergenList.push({
        menuItemId: action.payload.menuItemId,
        menuAllergen: action.payload.allergen,
      });
      state.hasMenuItemAllergenChanged = true;
    },
    removeSupplementFromList: (state, action: PayloadAction<number>) => {
      state.menuItemAllergenList = state.menuItemAllergenList.filter(
        (menuItemAllergen, menuItemAllergenIndex) =>
          menuItemAllergenIndex !== action.payload
      );
      state.hasMenuItemAllergenChanged = true;
    },
    setSelectedSupplement: (
      state,
      action: PayloadAction<IMenuItemAllergenEntity | null>
    ) => {
      state.selectedMenuItemAllergen = action.payload;
    },
  },
});

export const menuItemAllergenReducer = menuItemAllergenSlice.reducer;
export const menuItemAllergenActions = menuItemAllergenSlice.actions;
