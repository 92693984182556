import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import ReviewDetailsBox from "../ReviewDetailsBox/ReviewDetailsBox";
import { TReviewsOverviewCardProps } from "./types/TReviewsOverviewCardProps";
import { IReviewsEntity } from "../../../../../../../common/Entities/IReviewsEntity";
import { LoadingStatesEnum } from "../../../../../../../common/enums/LoadingStatesEnum";

export default function ReviewsOverviewCard(props: TReviewsOverviewCardProps) {
  const handleDisplayReviews = (
    reviews: IReviewsEntity[]
  ): JSX.Element | JSX.Element[] => {
    let reviewsToRender: JSX.Element[] = [];
    if (props.reviews.length === 0) {
      return <Typography>Aucune revue pour le moment</Typography>;
    }
    {
      props.reviews.map((review, index) => {
        if (index < 5) {
          reviewsToRender.push(
            <div key={index}>
              <ReviewDetailsBox review={review} />
            </div>
          );
        }
      });
    }
    return reviewsToRender;
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <Typography
        className="font-poppins"
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          color: "#2E2E2E",
          marginBottom: "10px",
        }}
      >
        Revues
      </Typography>
      {props.loadingState === LoadingStatesEnum.LOADING ? (
        <CircularProgress />
      ) : (
        handleDisplayReviews(props.reviews)
      )}
      {props.reviews.length > 0 && (
        <Grid
          container
          sx={{
            justifyContent: "end",
            width: "100%",
          }}
        >
          <Link href="/dashboard/reviews" color="inherit" underline="none">
            <Typography
              className="font-roboto"
              sx={{
                color: "#FBBC04",
                fontSize: "10px",
                textDecoration: "underline",
              }}
            >
              Voir plus
            </Typography>
          </Link>
        </Grid>
      )}
    </Box>
  );
}
