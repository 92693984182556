import { useEffect, useState } from "react";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import useCustomBarChartRepository from "../../../Repositories/HomePage/useCustomBarChartRepository";
import { fetchStat } from "../../../../../../utils/sharedFunctions";
import CustomBarChartBox from "../../../Components/BarChart/CustomBarChartBox/CustomBarChartBox";

export default function CustomBarChartContainer() {
  const [currentWeekOrdersLoadingState, setCurrentWeekOrdersLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.IDLE);

  const { onFindCurrentWeekOrdersNbr, currentWeekOrders } =
    useCustomBarChartRepository();

  useEffect(() => {
    if (currentWeekOrders === null) {
      fetchStat(onFindCurrentWeekOrdersNbr, setCurrentWeekOrdersLoadingState);
    }
  }, []);
  return (
    <CustomBarChartBox
      data={currentWeekOrders ?? []}
      loadingState={currentWeekOrdersLoadingState}
    />
  );
}
