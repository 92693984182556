import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CustomBarChart from "../CustomBarChart/CustomBarChart";
import { TCustomBarChartBoxProps } from "./types/TCustomBarChartBoxProps";
import { TCustomBarChartAttributes } from "../CustomBarChart/types/TCustomBarChartAttributes";
import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";

export default function CustomBarChartBox(props: TCustomBarChartBoxProps) {
  const emptyData = [
    { date: new Date("2024-01-01"), value: 0 },
    { date: new Date("2024-01-02"), value: 0 },
    { date: new Date("2024-01-03"), value: 0 },
    { date: new Date("2024-01-04"), value: 0 },
    { date: new Date("2024-01-05"), value: 0 },
    { date: new Date("2024-01-06"), value: 0 },
    { date: new Date("2024-01-07"), value: 0 },
  ];
  const getBarsData = (data: TCustomBarChartAttributes[]) => {
    return data.length === 0 ? emptyData : data;
  };
  // Fonction pour formater sans le point
  const formatDate = (date: Date) => {
    const locale = "fr-FR";
    const day = date.getDate();
    const month = date
      .toLocaleString(locale, { month: "short" })
      .replace(".", ""); // Supprime le point
    return `${day} ${month}`;
  };
  function getCurrentWeekDates() {
    const now = new Date();
    const currentDay = now.getDay(); // Jour de la semaine (0 pour dimanche)
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay; // Décalage pour obtenir lundi
    const monday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + diffToMonday
    );
    const sunday = new Date(
      monday.getFullYear(),
      monday.getMonth(),
      monday.getDate() + 6
    );

    const formattedMonday = formatDate(monday);
    const formattedSunday = formatDate(sunday);

    return `${formattedMonday} - ${formattedSunday}`;
  }
  return (
    <Box
      sx={{
        padding: "25px",
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        borderRadius: "10px",
        width: "800px",
        height: "351px",
        backgroundColor: "white",
      }}
    >
      <Grid container direction={"column"}>
        <Grid container direction={"column"}>
          <Typography
            className="font-inter"
            sx={{ fontSize: "17px", fontWeight: "bold", color: "#2E2E30" }}
          >
            Commandes hebdomadaires
          </Typography>
          <Typography
            className="font-inter"
            sx={{ fontSize: "10px", fontWeight: "400", color: "#2E2E30" }}
          >
            {getCurrentWeekDates()}
          </Typography>
        </Grid>
        <Grid item>
          {props.loadingState === LoadingStatesEnum.LOADING ? (
            <CircularProgress />
          ) : (
            <CustomBarChart data={getBarsData(props.data)} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
