import React from "react";
import { TDeleteMenuItemConfirmationModalProps } from "./types/TDeleteMenuItemConfirmationModalProps";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal/ConfirmationModal";

export default function DeleteMenuItemConfirmationModal(
  props: TDeleteMenuItemConfirmationModalProps
) {
  return (
    <ConfirmationModal
      title={`Etes-vous sûr de vouloir supprimer : ${props.menuItem.name} `}
      handleAgree={props.handleDeleteMenuItem}
      handleClose={props.handleClose}
      isOpen={props.isOpen}
    />
  );
}
